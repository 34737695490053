﻿(function (globalScope) {
    "use strict";
    globalScope._lmsToolkit = globalScope._lmsToolkit || {};
    let toolkit = globalScope._lmsToolkit;
    toolkit.getLocalizedString = toolkit.getLocalizedString || function (key) {
        let result = key;
        try {
            if (typeof (globalScope._lmsLocalizedTexts) !== 'undefined'
                && globalScope._lmsLocalizedTexts !== null) {
                let localizationData = globalScope._lmsLocalizedTexts[key];
                if (typeof (localizationData) !== 'undefined' && localizationData !== null) {
                    let uiCulture = window.uiCulture;
                    result = localizationData[uiCulture] || localizationData[uiCulture.substr(0, 2)] || key;
                }
                else {
                    result = key;
                }
            }
        }
        catch {
            result = key;
        }
        var args = Array.prototype.slice.call(arguments, 1);
        return result.replace(/{(\d+)}/g, function (match, number) {
            return typeof args[number] !== 'undefined'
                ? args[number]
                : match;
        });
    };
    toolkit.getCookieName = toolkit.getCookieName || function (name) {
        try {
            return (globalScope._cookiePrefix || '') + name;
        }
        catch {
            return name;
        }
    };
})(window);