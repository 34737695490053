let parseCss = function(styleElement){
  var sheetRef=styleElement;
  var selectorTexts = [];
  let cssRules = null;
  if(sheetRef.sheet != undefined){
    cssRules = sheetRef.sheet.cssRules;
  }else if(sheetRef.styleSheet != undefined){
    cssRules = sheetRef.styleSheet.cssRules;
  }else{
    cssRules = sheetRef.cssRules;
  }
  for (var i=0; i<cssRules.length; i++){
  //var sheet = sheetRef.sheet ? sheetRef.sheet : sheetRef.styleSheet;
  var sheet = null;
  if(sheetRef.sheet != undefined){
    sheet = sheetRef.sheet;
  }else if(sheetRef.styleSheet != undefined){
    sheet = sheetRef.styleSheet;
  }else{
    sheet = sheetRef;
  }
  if (sheet.cssRules.length > 0) {
    if(sheet.cssRules[i].selectorText != undefined){
      selectorTexts.push(sheet.cssRules[i].selectorText)
    }
    if(sheet.cssRules[i].cssRules != undefined){
      if(sheet.cssRules[i].cssRules.length > 0){
        var slc = sheet.cssRules[i].cssRules;
        for(let j=0;j<slc.length;j++){
          selectorTexts.push(slc[j].selectorText);
        }
      }
    }

  }}
  allIds = [];
  allClassNames = [];
  selectorTexts.forEach(function(selector){
    if(selector != undefined){
      var result = parseSelectors(selector)
      if(result.id != undefined){
        allIds.push(result.id);
      }
      if(result.classNames != undefined){
        if(result.classNames.length > 0){
          allClassNames.push(...result.classNames);
        }
      }

    }


  })
  //console.log([...new Set(allIds)]);
  //console.log([...new Set(allClassNames)]);
  return [...new Set(allClassNames)];
}
let parseSelectors = function(selector){

  var firstId = selector.indexOf('#');
  var lastId = selector.lastIndexOf('#');
  if (firstId !== -1 && firstId !== lastId) {
    throw new Error('Expected a maximum of one id in selector');
  }

  var classNames = [],
      id = null;
  if (firstId !== -1) {
    id = selector.match(/#[^.]*/)[0].slice(1);
    classNames = selector.replace('#' + id, '').split('.');
  } else {
    classNames = selector.split('.');
  }
  for(let i=0;i<classNames.length;i++){
    classNames[i] = classNames[i].replace(/[+>~,]/gi,"")
    classNames[i] = classNames[i].split(" ")[0];
    classNames[i] = classNames[i].split(":")[0];
    classNames[i] = classNames[i].split("[")[0];
    classNames[i] = classNames[i].trim();
  }
  classNames.shift();

  return {
    classNames: classNames,
    id: id
  };
}