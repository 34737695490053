﻿(function ($) {
    "use strict";

    $.widget("lms.contentStateTracker", {
        _apiLoadEndPoint: '/courseapi/getstate/',
        _apiSaveEndPoint: '/courseapi/setstate/',
        _state : {},
        options: {

        },
        _create: function () {
            var options = this.options;
            options.id = this.element.data('content-tracker-id');
            options.contentId = this.element.data('content-id');
            this._load(function (state) {
                for (var prop in state) {
                    if (state.hasOwnProperty(prop)) {
                        $('[data-content-tracker-key="' + this.options.id + '-' + prop + '"]').trigger('contentTrackerStatePush');
                    }
                }
                setTimeout(function () {
                    this._persist();
                }.bind(this), 5000);
                $('[data-content-tracker-id="' + this.options.id + '"]').trigger('contentTrackerReady');
            }.bind(this));
        },
        update: function (key, state) {
            this._state[key] = state;
        },
        getState: function (key, callback) {
            if (typeof (key) === "function") {
                key(this._state);
            }
            else if (typeof(key) === "undefined") {
                if (typeof (callback) === "function") {
                    callback(this._state);
                }
                return this._state;
            }
            else {
                let val = typeof (this._state[key]) !== "undefined" ? this._state[key] : null;
                if (typeof (callback) === "function") {
                    callback(val);
                }
                return val;
            }
        },
        _load: function(callback) {
            $.get(this._apiLoadEndPoint + this.options.contentId, function (data) {
                this._state = data;
                if(typeof(callback) === "function") {
                    callback(data);
                }
            }.bind(this));
        },
        _persist: function (callback) {
            if (this._state && Object.keys(this._state).length === 0
                && Object.getPrototypeOf(this._state) === Object.prototype) {
                if (typeof (callback) === "function") {
                    callback(true);
                }
                return;
            }
            let postData = { StateData: this._state };
            $.ajax({
                type: 'POST',
                url: this._apiSaveEndPoint + this.options.contentId,
                data: JSON.stringify(postData),
                success: function (data) { 
                    setTimeout(function () {
                        this._persist();
                    }.bind(this), 5000);
                    if (typeof (callback) === "function") {
                        callback(data);
                    }
                }.bind(this),
                contentType: "application/json",
                dataType: 'json'
            });
        }
    });
    $('.lms-content-state-tracker').contentStateTracker();
})(jQuery);