﻿(function ($) {
    "use strict";

    $.widget("lms.videoPlayer", {
        options: {

        },
        _create: function () {
            var options = this.options;
            options.state = {};
            options.id = this.element.attr('id');
            options.videoUrl = this.element.data('video-url');
            options.stateTracker = this.element.data('content-tracker');
            let srcWidth = parseFloat(this.element.data('width'));
            let srcHeight = parseFloat(this.element.data('height'));
            if (!isNaN(srcWidth) && !isNaN(srcHeight)) {
                options.aspectRatio = srcWidth / srcHeight;
            }
            this.element.append(this._generateWidgetElement());
            $(this.element).attr('data-content-tracker-key', options.stateTracker + '-' + options.id);
            this.element.on('contentTrackerStatePush', function () {
                if (typeof (options.stateTracker) !== "undefined") {
                    this._getTracker().contentStateTracker("getState", options.id, function (state) {
                        if (typeof (state) !== "undefined") {
                            this.options.state = state;
                        }
                    }.bind(this));

                }
            }.bind(this));
            
            if (typeof (options.stateTracker) !== "undefined") {
                this._getTracker().contentStateTracker("getState", options.id, function (state) {
                    if (typeof (state) !== "undefined") {
                        this.options.state = state;
                    }
                }.bind(this));
                
            }
        },
        _generateWidgetElement: function () {
            var videoElem = $("<video/>");
            videoElem.attr('width', '720');
            if (typeof (this.options.aspectRatio) !== 'undefined') {
                videoElem.attr('height', parseInt(720 / this.options.aspectRatio));
            }
            videoElem.attr('loop', 'false');
            videoElem.attr('controls', 'true');
            videoElem.attr('id', this.options.id + '-video');
            videoElem.attr('src', this.options.videoUrl);
            videoElem.on('timeupdate', function () {
                this._onTimeUpdate();
            }.bind(this));
            videoElem.on('loadedmetadata', function () {
                this._onLoadedMetaData();
            }.bind(this));
            videoElem.on('play', function () {
                this._onPlay();
            }.bind(this));
            this._video = videoElem[0];
            return videoElem;
        },
        _onLoadedMetaData: function () {
            /*var state = this.options.state;
            if (typeof (state.currentTime) !== "undefined") {
                var time = parseFloat(state.currentTime);
                if (!isNaN(time)) {
                    this.seekTo(time);
                }
            }*/
        },
        _onPlay: function() {
            if (typeof (this.options.stateInit) === "undefined") {
                var state = this.options.state;
                if (typeof (state) !== "undefined") {
                    if (typeof (state.currentTime) !== "undefined") {
                        var time = parseFloat(state.currentTime);
                        if (!isNaN(time)) {
                            this.seekTo(time);
                        }
                    }
                }
                this.options.stateInit = true;
            }
        },
        _onTimeUpdate: function () {
            if (typeof (this.options.stateInit) !== "undefined") {
                this.options.state.currentTime = this._video.currentTime;
                this._updateState();
            }
        },
        _getTracker: function() {
            return $('[data-content-tracker-id="' + this.options.stateTracker + '"]');
        },
        _updateState: function () {
            if (typeof (this.options.stateTracker) !== "undefined") {
                try {
                    this._getTracker().contentStateTracker("update", this.options.id, this.options.state);
                    //_this.options.stateTracker.update(_this.options.id, _this.options.state);
                }
                catch (e) { }
            }
        },
        _loadState: function () {
            if (typeof (this.options.state) !== "undefined") {
                var state = this.options.state;
                if (typeof (this._video) !== "undefined") {
                    if (typeof (state.currentTime) !== "undefined") {
                        var currentTime = parseFloat(state.currentTime);
                        if (!isNaN(currentTime) && currentTime >= 0 && currentTime <= this._video.duration) {
                            this.seekTo(currentTime);
                        }
                    }
                }
            }
        },
        seekTo: function (time) {
            if (time <= this._video.duration && time >= 0) {
                this._video.currentTime = time;
            }
        }
    });
    $('.lms-video-player').videoPlayer();
})(jQuery);