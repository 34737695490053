﻿(function ($, globalScope) {
    var _showModal = function (content, title) {
        title = title || '';
        var html = '<div id="formModal" class="modal faled" tabindex="-1" role="dialog" aria-labelledby="confirm-modal" aria-hidden="true">'
            + '<div class="modal-dialog"><div class="modal-content">'
            + '<div class="modal-header">' + title + '<a class="close" data-dismiss="modal">x</a></div>'
            + '<div class="modal-body">'
            + content
            + '</div>'
            + '<div class="modal-footer"></div>'
            + '</div></div>'
            + '</div>';
        $("body").append(html);
        $("#formModal").modal();
        $("#formModal").modal("show");
        $('#formModal').on('hidden.bs.modal', function (e) {
            $(this).remove();
        });
    };
    var _hideModal = function () {
        $('#formModal').modal('hide');
    }
    var _isMethodProxySafe = function (method) {
        return method === "GET" || method === "POST";
    };
    var _asyncOnBeforeSend = function (xhr, method) {
        if (!_isMethodProxySafe(method)) {
            xhr.setRequestHeader("X-HTTP-Method-Override", method);
        }
    };

    var _asyncRequest = function (dlg, reqOptions, successCallback, errorCallback) {
        var method;
        let me = this;
        $.extend(reqOptions, {
            beforeSend: function (xhr) {
                _asyncOnBeforeSend(xhr, method);
                $('.error-summary', dlg).hide('fast');
                $(dlg).addClass('loading');
                return true;
            },
            complete: function () {
                $(dlg).removeClass('loading');
            },
            success: function (data, status, xhr) {
                try {
                    successCallback(data, status, xhr);
                }
                catch (e) {
                    console.error(e);
                }
            },
            error: function (data, status, xhr) {
                try {
                    errorCallback(data, status, xhr);
                }
                catch (e) {
                    console.error(e);
                }
            }
        });

        if (typeof (reqOptions.data) === 'object') {
            if (typeof (reqOptions.data.append) === 'function') {
                reqOptions.data.append("X-Requested-With", "XMLHttpRequest");
            }
            else {
                $.extend(reqOptions.data, { "X-Requested-With": "XMLHttpRequest" });
            }
        }
        else {
            reqOptions.data.push({ name: "X-Requested-With", value: "XMLHttpRequest" });
        }
        method = reqOptions.type.toUpperCase();
        if (!_isMethodProxySafe(method)) {
            reqOptions.type = "POST";
            if (typeof (reqOptions.data) === 'object') {
                if (typeof (reqOptions.data.append) === 'function') {
                    reqOptions.data.append("X-HTTP-Method-Override", method);
                }
                else {
                    $.extend(reqOptions.data, { "X-HTTP-Method-Override": method });
                }
            }
            else {
                reqOptions.data.push({ name: "X-HTTP-Method-Override", value: method });
            }
        }
        if (reqOptions.contentType === 'application/json') {
            reqOptions.data = JSON.stringify(reqOptions.data);
        }

        reqOptions.processData = false;
        $.ajax(reqOptions);
    };
    var _setDialogError = function (dlgParent, statusMessage, errorMessages) {
        let errorContainer = $('.error-summary', dlgParent);
        if (errorContainer.length > 0) {
            errorContainer.hide('slow');
            errorContainer.empty();
            errorContainer.append($('<b/>').text(statusMessage));
            if (typeof (errorMessages) === 'object' && Array.isArray(errorMessages) &&
                errorMessages.length > 0) {
                let errorList = $('<ul/>');
                for (let i = 0; i < errorMessages.length; i++) {
                    errorList.append('<li>' + errorMessages[i] + '</li>');
                }
                errorContainer.append(errorList);
            }
            errorContainer.show('slow');
        }
    };

    var _rowCommandSelectors = ['.row-commands .edit-link', '.row-commands .download-link', '.row-commands .remove-link'];

    $(document).ready(function () {
        $(document).on('click', '.attachments .remove-link', function (ev) {
            ev.preventDefault();
            let fileId = $(this).data('file-id');
            let targetId = $(this).data('target-id');
            if (fileId === null || targetId === null) {
                return;
            }
            let postUrl = $(this).attr('href');
            var formTemplate = $('.template-container .remove-file-attachment-dlg').clone();
            formTemplate.attr('id', 'RemoveFileAttachmentDlg_' + fileId + '_' + targetId);
            formTemplate.attr('data-file-id', fileId);
            formTemplate.attr('data-target-id', targetId);
            formTemplate.attr('data-post-target', postUrl);
            //var title = $('.content-panel-title', formTemplate).text();
            //$('.content-panel-title', formTemplate).remove();
            _showModal(formTemplate[0].outerHTML);
        });
        $(document).on('click', '.file-attachment.add-link', function (ev) {
            ev.preventDefault();
            let targetId = $(this).data('target-id');
            if (targetId === null) {
                return;
            }
            let postUrl = $(this).attr('href');
            var formTemplate = $('.template-container .add-file-attachment-dlg').clone();
            formTemplate.attr('id', 'AddFileAttachmentDlg_' + targetId);
            formTemplate.attr('data-target-id', targetId);
            formTemplate.attr('data-post-target', postUrl);
            _showModal(formTemplate[0].outerHTML);
        });
        $(document).on('click', '.attachments .edit-link', function (ev) {
            ev.preventDefault();
            let fileId = $(this).data('file-id');
            let targetId = $(this).data('target-id');
            if (fileId === null || targetId === null) {
                return;
            }
            let postUrl = $(this).attr('href');
            let dataUrl = $(this).parents('.row-commands').data('form-data-url');
            if (dataUrl === null || postUrl === null) {
                return;
            }
            // todo: loader anim
            $.getJSON(dataUrl, function (data) {
                var formTemplate = $('.template-container .edit-file-attachment-dlg').clone();
                formTemplate.attr('id', 'EditFileAttachmentDlg_' + fileId + '_' + targetId);
                formTemplate.attr('data-file-id', fileId);
                formTemplate.attr('data-target-id', targetId);
                formTemplate.attr('data-post-target', postUrl);
                $('#AttachmentName', formTemplate).attr('value', data.data.name);
                $('#AttachmentDescription', formTemplate).text(data.data.description);
                $('#SortOrder', formTemplate).attr('value', data.data.sortOrder);
                _showModal(formTemplate[0].outerHTML);
            });

        });
        $(document).on('click', '.add-file-attachment-dlg .btn-default', function (ev) {
            ev.preventDefault();
            let dlgParent = $(this).parents('.add-file-attachment-dlg');
            let form = $('form.add-attachment-form', dlgParent);
            let postUrl = dlgParent.data('post-target');
            let targetId = dlgParent.data('target-id');
            var formData = new FormData();
            formData.append('TargetId', targetId);
            $.each(form.serializeArray(), function (i, v) {
                formData.append(v.name, v.value);
            });
            $('input[type=file]', form).each(function () {
                var file = this;
                $.each(file.files, function (n, v) {
                    formData.append(file.name, v);
                });
            });
            let reqOptions = {
                url: postUrl,
                data: formData,
                processData: false,
                contentType: false,
                type: 'PUT'
            };
            _asyncRequest(dlgParent, reqOptions, function (responseData, status, xhr) {
                if (responseData.success === true) {
                    $('.content-panel-content', dlgParent).toggle();
                    let rows = $('.attachments .attachment-row');
                    let newRow = $('.template-container .row-templates .attachment-row').clone();
                    newRow.attr('id', 'attachment-row-' + responseData.data.fileId + '-' + responseData.data.targetId)
                    $('.attachment-name', newRow).text(responseData.data.name);
                    $('.attachment-contenttype', newRow).text(responseData.data.contentType);
                    $('.attachment-description', newRow).text(responseData.data.description);
                    $.each(_rowCommandSelectors, function (n, v) {
                        $(v, newRow).attr('data-file-id', responseData.data.fileId);
                        $(v, newRow).attr('data-target-id', responseData.data.targetId);
                    });

                    if (responseData.data.sortOrder < 1) {
                        responseData.data.sortOrder = 1;
                    }
                    if (rows.length === 0 || rows.length < responseData.data.sortOrder) {
                        $('.attachments tbody').append(newRow[0].outerHTML);
                    }
                    else {
                        $(rows[responseData.data.sortOrder - 1]).before(newRow);
                    }

                    setTimeout(_hideModal, 2000);
                }
                else {
                    let errMsg = globalScope._lmsToolkit.getLocalizedString('GENERIC_ERROR');
                    let errorList = [];
                    if (typeof (responseData) === 'object') {
                        if (typeof (responseData.resultStatus) === 'string') {
                            errMsg = responseData.resultStatus;
                        }
                        if (typeof (responseData.messages) === 'object'
                            && Array.isArray(responseData.messages) && responseData.messages.length > 0) {
                            errorList = errorList.concat(responseData.messages);
                        }
                    }
                    _setDialogError(dlgParent, errMsg, errorList);
                }
            }, function (data, status, xhr) {
                let responseData = data.responseJSON;
                let errMsg = globalScope._lmsToolkit.getLocalizedString('GENERIC_ERROR');
                let errorList = [];
                if (typeof (responseData) === 'object') {
                    if (typeof (responseData.resultStatus) === 'string') {
                        errMsg = responseData.resultStatus;
                    }
                    if (typeof (responseData.messages) === 'object'
                        && Array.isArray(responseData.messages) && responseData.messages.length > 0) {
                        errorList = errorList.concat(responseData.messages);
                    }
                }
                _setDialogError(dlgParent, errMsg, errorList);
            });
        });
        $(document).on('click', '.edit-file-attachment-dlg .btn-default', function (ev) {
            ev.preventDefault();
            let dlgParent = $(this).parents('.edit-file-attachment-dlg');
            let form = $('form.edit-attachment-form', dlgParent);
            let postUrl = dlgParent.data('post-target');
            let fileId = dlgParent.data('file-id');
            let targetId = dlgParent.data('target-id');
            let reqData = {};
            reqData['FileId'] = fileId;
            reqData['TargetId'] = targetId;
            $.each(form.serializeArray(), function (i, v) {
                reqData[v.name] = isNaN(v.value) ? v.value : (isNaN(parseFloat(v.value)) ? parseInt(v.value) : parseFloat(v.value));
            });
            let reqOptions = {
                url: postUrl,
                data: reqData,
                processData: false,
                contentType: "application/json",
                type: 'POST',
                dataType: 'json'
            };
            _asyncRequest(dlgParent, reqOptions, function (responseData, status, xhr) {
                if (responseData.success === true) {
                    $('.content-panel-content', dlgParent).toggle();

                    let rowId = 'attachment-row-' + responseData.data.fileId + '-' + responseData.data.targetId;
                    let row = $('.attachments .attachment-row#' + rowId).detach();
                    let rows = $('.attachments .attachment-row');
                    if (responseData.data.sortOrder < 1) {
                        responseData.data.sortOrder = 1;
                    }
                    if (rows.length === 0 || rows.length < responseData.data.sortOrder) {
                        $('.attachments tbody').append(row);
                    }
                    else {
                        $(rows[responseData.data.sortOrder - 1]).before(row);
                    }

                    setTimeout(_hideModal, 2000);
                }
                else {
                    let errMsg = globalScope._lmsToolkit.getLocalizedString('GENERIC_ERROR');
                    let errorList = [];
                    if (typeof (responseData) === 'object') {
                        if (typeof (responseData.resultStatus) === 'string') {
                            errMsg = responseData.resultStatus;
                        }
                        if (typeof (responseData.messages) === 'object'
                            && Array.isArray(responseData.messages) && responseData.messages.length > 0) {
                            errorList = errorList.concat(responseData.messages);
                        }
                    }
                    _setDialogError(dlgParent, errMsg, errorList);
                }
            }, function (data, status, xhr) {
                let responseData = data.responseJSON;
                let errMsg = globalScope._lmsToolkit.getLocalizedString('GENERIC_ERROR');
                let errorList = [];
                if (typeof (responseData) === 'object') {
                    if (typeof (responseData.resultStatus) === 'string') {
                        errMsg = responseData.resultStatus;
                    }
                    if (typeof (responseData.messages) === 'object'
                        && Array.isArray(responseData.messages) && responseData.messages.length > 0) {
                        errorList = errorList.concat(responseData.messages);
                    }
                }
                _setDialogError(dlgParent, errMsg, errorList);
            });
        });
        $(document).on('click', '.remove-file-attachment-dlg .btn-default', function (ev) {
            ev.preventDefault();
            let dlgParent = $(this).parents('.remove-file-attachment-dlg');
            let postUrl = dlgParent.data('post-target');
            let fileId = dlgParent.data('file-id');
            let targetId = dlgParent.data('target-id');
            let reqData = {};
            reqData['FileId'] = fileId;
            reqData['TargetId'] = targetId;
            reqData['RemoveFileIfNotUsed'] = $('#RemoveFileIfNotUsed', dlgParent).is(':checked');
            reqData['ConfirmOp'] = $('#ConfirmOp', dlgParent).is(':checked');
            let reqOptions = {
                url: postUrl,
                data: reqData,
                processData: false,
                contentType: "application/json",
                type: 'DELETE',
                dataType: 'json'
            };
            _asyncRequest(dlgParent, reqOptions, function (responseData, status, xhr) {
                if (responseData.success === true) {
                    $('.content-panel-content', dlgParent).toggle();
                    $('.attachments #attachment-row-' + fileId + '-' + targetId).toggle('fadeOut', function () { $(this).remove(); });
                    setTimeout(_hideModal, 2000);
                }
                else {
                    let errMsg = globalScope._lmsToolkit.getLocalizedString('GENERIC_ERROR');
                    let errorList = [];
                    if (typeof (responseData) === 'object') {
                        if (typeof (responseData.resultStatus) === 'string') {
                            errMsg = responseData.resultStatus;
                        }
                        if (typeof (responseData.messages) === 'object'
                            && Array.isArray(responseData.messages) && responseData.messages.length > 0) {
                            errorList = errorList.concat(responseData.messages);
                        }
                    }
                    _setDialogError(dlgParent, errMsg, errorList);
                }
            }, function (data, status, xhr) {
                let responseData = data.responseJSON;
                let errMsg = globalScope._lmsToolkit.getLocalizedString('GENERIC_ERROR');
                let errorList = [];
                if (typeof (responseData) === 'object') {
                    if (typeof (responseData.resultStatus) === 'string') {
                        errMsg = responseData.resultStatus;
                    }
                    if (typeof (responseData.messages) === 'object'
                        && Array.isArray(responseData.messages) && responseData.messages.length > 0) {
                        errorList = errorList.concat(responseData.messages);
                    }
                }
                _setDialogError(dlgParent, errMsg, errorList);
            });
        });
    });
})(jQuery, window);