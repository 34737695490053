﻿(function ($, globalScope) {
    "use strict";
    var data_validation = "unobtrusiveValidation";

    $.widget("lms.embeddedAdd", {
        options: {

        },
        _create: function () {
            let options = this.options;
            let me = this;
            let elem = this.element;
            options.formUrl = elem.data('form-url');
            options.updateUrl = elem.data('update-url');

            try {
                options.translator = globalScope._lmsToolkit.getLocalizedString || this._fallbackTranslator;
            }
            catch {
                options.translator = this._fallbackTranslator;
            }
            let formCount = parseInt(elem.data('form-count'));
            if (elem.hasClass('embedded-add-multi') && !isNaN(formCount) && formCount > 0) {
                if (typeof (options.updateUrl) === 'undefined' || options.updateUrl == '') {
                    return;
                }
                options.formIds = [];
                options.linkElems = [];
                let labelElem = this.element.siblings('label');
                let linkWrapper = {};
                if (labelElem.length > 0) {
                    linkWrapper = labelElem.children('.command-wrapper');
                    if (linkWrapper.length === 0) {
                        labelElem.append(this._generateLinkWrapper());
                        linkWrapper = labelElem.children('.command-wrapper');
                    }
                }
                else {
                    linkWrapper = this.element.siblings('.command-wrapper');
                    if (linkWrapper.length === 0) {
                        this.element.insertAfter(this._generateLinkWrapper());
                        linkWrapper = this.element.siblings('.command-wrapper');
                    }
                }
                for (let i = 0; i < formCount; i++) {
                    let formId = elem.data('form-id-' + i) || '';
                    options.formIds.push(formId);
                    let linkElem = this._generateWidgetElementIndexed(elem, i);
                    linkWrapper.append(linkElem);
                }
            }
            else {
                if (typeof (options.formUrl) === 'undefined' || options.formUrl == ''
                    || typeof (options.updateUrl) === 'undefined' || options.updateUrl == '') {
                    return;
                }
                options.formId = this.element.data('form-id');
                options.linkElem = this._generateWidgetElement();
                let labelElem = this.element.siblings('label');
                if (labelElem.length > 0) {
                    let linkWrapper = labelElem.children('.command-wrapper');
                    if (linkWrapper.length === 0) {
                        labelElem.append(this._generateLinkWrapper());
                        linkWrapper = labelElem.children('.command-wrapper');
                    }
                    linkWrapper.append(options.linkElem);
                }
                else {
                    let linkWrapper = this.element.siblings('.command-wrapper');
                    if (linkWrapper.length === 0) {
                        this.element.insertAfter(this._generateLinkWrapper());
                        linkWrapper = this.element.siblings('.command-wrapper');
                    }
                    linkWrapper.append(options.linkElem);
                }
            }
        },
        _fallbackTranslator: function(key) {
            return key;
        },
        _generateLinkWrapper: function () {
            return $('<span class="command-wrapper"></span>');
        },
        _generateWidgetElementIndexed: function (srcElement, idx) {
            let me = this;
            let linkElem = $('<a>' + (srcElement.data('link-label-' + idx) || this.options.translator('ADD_NEW')) + '</a>');
            linkElem.attr('target', '_blank');
            linkElem.attr('href', srcElement.data('form-url-' + idx));
            linkElem.addClass('add-link');
            linkElem.on('click', function (ev) {
                ev.preventDefault();
                this._asyncRequest(linkElem, {
                    url: srcElement.data('form-url-' + idx),
                    type: 'GET',
                    data: []
                }, function (data, status, xhr) {
                    let doc = $(data);
                    try {
                        let formElem = $('form[action="' + srcElement.data('form-url-' + idx) + '"], form#' + srcElement.data('form-id-' + idx), doc);
                        if (formElem.length > 0) {
                            formElem.addClass('modal_add_form');
                            me._showModal(formElem[0].outerHTML);
                            window.bindColorPickers();
                        }
                    }
                    catch (e) {
                        console.log('Indexed widget element generation failed: ' + (e.message || 'unspecified error'));
                    }
                }, function (data, status, xhr) {

                });
            }.bind(this));
            return linkElem;
        },
        _generateWidgetElement: function () {
            let me = this;
            let linkElem = $('<a>' + this.options.translator('ADD_NEW') + '</a>');
            linkElem.attr('target', '_blank');
            linkElem.attr('href', this.options.formUrl);
            linkElem.addClass('add-link');
            linkElem.on('click', function (ev) {
                ev.preventDefault();
                this._asyncRequest(linkElem, {
                    url: this.options.formUrl,
                    type: 'GET',
                    data: []
                }, function (data, status, xhr) {
                        let doc = $(data);
                        try {
                            let formElem = $('form[action="' + me.options.formUrl + '"], form#' + me.options.formId, doc);
                            if (formElem.length > 0) {
                                formElem.addClass('modal_add_form');
                                me._showModal(formElem[0].outerHTML);
                                window.bindColorPickers();
                            }
                        }
                        catch (e) {
                            console.log('Indexed widget element generation failed: ' + (e.message || 'unspecified error'));
                        }
                }, function (data, status, xhr) {

                });
            }.bind(this));
            return linkElem;
        },
        _showModal: function (content) {
            if (typeof (content) === 'string') {
                let me = this;
                var html = '<div id="formModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="confirm-modal" aria-hidden="true">';
                html += '<div class="modal-dialog">';
                html += '<div class="modal-content">';
                html += '<div class="modal-header">';
                html += '<a class="close" data-dismiss="modal">×</a>';
                html += '</div>';
                html += '<div class="modal-body embedded-add-modal">';
                html += content;
                html += '</div>';
                html += '<div class="modal-footer">';
                html += '</div>';  // content
                html += '</div>';  // dialog
                html += '</div>';  // footer
                html += '</div>';
                $("body").append(html);
                $("#formModal").modal();
                $("#formModal").modal("show");
                $('#formModal').on('hidden.bs.modal', function (e) {
                    $(this).remove();
                });
                $('#formModal').on('submit', '.embedded-add-modal form', function (evt) {
                    evt.preventDefault();
                    if (!me.validate(this)) {
                        return;
                    }
                    me._asyncRequest(this, {
                        url: this.action + (this.action.indexOf('?') > -1 ? '&' : '?') + 'fromAjax=1',
                        type: this.method || "GET",
                        data: $(this).serializeArray()
                    }, function (data, status, xhr) {
                        try {
                            let newObjectId = '';
                            let formSuccess = false;
                            if (typeof (xhr.responseJSON) !== 'undefined' && xhr.responseJSON !== null) {
                                newObjectId = xhr.responseJSON.id;
                                formSuccess = true;
                            }
                            else {
                                let doc = $(data);
                                let formElem = $('form[action="' + me.options.formUrl + '"], form#' + me.options.formId, doc);
                                if (formElem.length > 0) {
                                    formElem.addClass('modal_add_form');
                                    $('#formModal .embedded-add-modal form').replaceWith(formElem);
                                    window.bindColorPickers();
                                }
                                formSuccess = false;
                            }

                            if (formSuccess) {
                                // possibly successfull submit
                                if (me.element[0].nodeName === 'SELECT') {
                                    $.get(me.options.updateUrl, function (data2, xhr2) {
                                        let defElement = $('option:eq(0)', me.element);
                                        me.element.empty();
                                        if (defElement.val() === '') {
                                            me.element.append(defElement);
                                        }
                                        me.element.append(
                                            $.map(data2, function (val) {
                                                return $('<option>' + val.name + '</option>').val(val.id);
                                            })
                                        );
                                        if (typeof (xhr.responseJSON) !== 'undefined' && xhr.responseJSON !== null) {
                                            me.element.val(xhr.responseJSON.id).change();
                                        }
                                        $("#formModal").modal("hide");
                                    });
                                }
                                else {
                                    $("#formModal").modal("hide");
                                }
                            }
                        }
                        catch (e) {
                            console.log('Indexed widget element generation failed: ' + (e.message || 'unspecified error'));
                        }
                    }, function (data, status, xhr) {

                    });
                });
            }
        },
        _asyncRequest: function (element, reqOptions, successCallback, errorCallback) {
            var method;
            let me = this;
            $.extend(reqOptions, {
                beforeSend: function (xhr) {
                    me.asyncOnBeforeSend(xhr, method);
                    $(element).addClass('loading');
                    return true;
                },
                complete: function () {
                    $(element).removeClass('loading');
                },
                success: function (data, status, xhr) {
                    try {
                        let $element = $(element);

                        successCallback(data, status, xhr);
                    }
                    catch {

                    }
                },
                error: function (data, status, xhr) {
                    try {
                        errorCallback(data, status, xhr);
                    }
                    catch {

                    }
                }
            });
            reqOptions.data.push({ name: "X-Requested-With", value: "XMLHttpRequest" });

            method = reqOptions.type.toUpperCase();
            if (!this.isMethodProxySafe(method)) {
                reqOptions.type = "POST";
                reqOptions.data.push({ name: "X-HTTP-Method-Override", value: method });
            }
            var $element = $(element);
            if ($element.is("form") && $element.attr("enctype") == "multipart/form-data") {
                var formdata = new FormData();
                $.each(reqOptions.data, function (i, v) {
                    formdata.append(v.name, v.value);
                });
                $("input[type=file]", $element).each(function () {
                    var file = this;
                    $.each(file.files, function (n, v) {
                        formdata.append(file.name, v);
                    });
                });
                $.extend(reqOptions, {
                    processData: false,
                    contentType: false,
                    data: formdata
                });
            }

            $.ajax(reqOptions);
        },
        asyncOnBeforeSend: function(xhr, method) {
            if (!this.isMethodProxySafe(method)) {
                xhr.setRequestHeader("X-HTTP-Method-Override", method);
            }
        },
        isMethodProxySafe: function(method) {
            return method === "GET" || method === "POST";
        },
        validate: function (form) {
            var validationInfo = $(form).data(data_validation);
            return !validationInfo || !validationInfo.validate || validationInfo.validate();
        }
    });
    $('.embedded-add').embeddedAdd();
})(jQuery, window);