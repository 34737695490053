﻿(function ($) {
    "use strict";

    $.widget("lms.audioPlayer", {
        options: {

        },
        _create: function () {
            var options = this.options;
            options.state = {};
            options.id = this.element.attr("id");
            options.audioUrl = this.element.data("audio-url");
            options.audioMimeType = this.element.data("mime-type");
            options.stateTracker = this.element.data("content-tracker");
            this.element.append(this._generateWidgetElement());
            $(this.element).attr("data-content-tracker-key", options.stateTracker + "-" + options.id);
            this.element.on("contentTrackerStatePush", function () {
                if (typeof (options.stateTracker) !== "undefined") {
                    this._getTracker().contentStateTracker("getState", options.id, function (state) {
                        if (typeof (state) !== "undefined") {
                            this.options.state = state;
                        }
                    }.bind(this));
                }
            }.bind(this));
            if (typeof (options.stateTracker) !== "undefined") {
                this._getTracker().contentStateTracker("getState", options.id, function (state) {
                    if (typeof (state) !== "undefined") {
                        this.options.state = state;
                    }
                }.bind(this));
            }
        },
        _generateWidgetElement: function () {
            var audioElem = $("<audio/>");
            audioElem.attr("controls", "true");
            audioElem.attr("width", "300");
            audioElem.attr("id", this.options.id + "-audio");
            audioElem.attr("loop", "false");
            var audioSrcElem = $("<source/>");
            audioSrcElem.attr("src", this.options.audioUrl);
            audioSrcElem.attr("type", this.options.audioMimeType);
            audioElem.append(audioSrcElem);
            audioElem.on("timeupdate", function () {
                this._onTimeUpdate();
            }.bind(this));
            audioElem.on("play", function () {
                this._onPlay();
            }.bind(this));
            this._audio = audioElem[0];
            return audioElem;
        },
        _onPlay: function () {
            if (typeof (this.options.stateInit) === "undefined") {
                var state = this.options.state;
                if (typeof (state) !== "undefined") {
                    if (typeof (state.currentTime) !== "undefined") {
                        var time = parseFloat(state.currentTime);
                        if (!isNaN(time)) {
                            this.seekTo(time);
                        }
                    }
                }
                this.options.stateInit = true;
            }
        },
        _onTimeUpdate: function () {
            if (typeof (this.options.stateInit) !== "undefined") {
                this.options.state.currentTime = this._audio.currentTime;
                this._updateState();
            }
        },
        _getTracker: function () {
            return $('[data-content-tracker-id="' + this.options.stateTracker + '"]');
        },
        _updateState: function () {
            if (typeof (this.options.stateTracker) !== "undefined") {
                try {
                    this._getTracker().contentStateTracker("update", this.options.id, this.options.state);
                }
                catch (e) { }
            }
        },
        _loadState: function () {
            if (typeof (this.options.state) !== "undefined") {
                var state = this.options.state;
                if (typeof (this._audio) !== "undefined") {
                    if (typeof (state.currentTime) !== "undefined") {
                        var currentTime = parseFloat(state.currentTime);
                        if (!isNaN(currentTime) && currentTime >= 0 && currentTime <= this._audio.duration) {
                            this.seekTo(currentTime);
                        }
                    }
                }
            }
        },
        seekTo: function (time) {
            if (time <= this._audio.duration && time >= 0) {
                this._audio.currentTime = time;
            }
        }
    });
    $('.lms-audio-player').audioPlayer();
})(jQuery);