﻿(function ($) {
    "use strict";

    $.widget("lms.fractionTrainer", {
        options: {

        },
        _create: function () {
            var options = this.options;
            if (typeof (options.id) === "undefined") {
                options.id = 'fraction-trainer-' + Math.floor(Math.random() * 10000);
            }
            this.element.html('');
            this.element.attr('id', options.id);
            this.element.append(this._generateWidgetElement());
            $('.fraction-value:not([readonly])', $(this.element)).change(function () { this._verify() }.bind(this));
            $('select#' + options.id + '-operand').change(function () { this._verify() }.bind(this));
            $(this.element).addClass('widget-ready');
            this._buildValues();
        },
        _generateWidgetElement: function () {
            var options = this.options;
            var trainer = $('<div class="fraction-trainer-body"></div>');

            var operand = $('<div class="fraction-operand"></div>');
            operand.attr('id', options.id + '-operand');
            var operandContainer = $('<div class="fraction-operand-container"></div>');
            operandContainer.append(operand);
            var equals = $('<div class="fraction-equals-container">&equals;</div>');
            var commandWrapper = $('<div class="trainer-commands-wrapper"></div>');
            var reloadIcon = $('<span class="trainer-command fas fa-redo-alt"></span>');
            reloadIcon.click(function () {
                this._buildValues();
            }.bind(this));
            var resultIcon = $('<span class="trainer-result glyphicon"></span>');
            resultIcon.attr('id', options.id + '-trainer-result');
            commandWrapper.append(resultIcon);
            commandWrapper.append(reloadIcon);
            trainer.append([this._generateFractionElement(options.id, 'fract1', -1, true), operandContainer,
                this._generateFractionElement(options.id, 'fract2', -1, true), equals,
                this._generateFractionElement(options.id, 'fractRes', 1), commandWrapper]);
            return trainer;
        },
        _generateFractionElement: function (baseId, elementKey, tabindex, readonly) {
            var numerator = $('<input class="fraction-value fraction-numerator" type="text"/>');
            numerator.attr('id', baseId + '-' + elementKey + '-num');

            var denominator = $('<input class="fraction-value fraction-denominator" type="text"/>');
            denominator.attr('id', baseId + '-' + elementKey + '-denom');

            if (readonly === true) {
                denominator.prop('readonly', true);
                numerator.prop('readonly', true);
                numerator.attr('tabindex', -1);
                denominator.attr('tabindex', -1);
            }
            else {
                if (typeof (tabindex) !== undefined && !isNaN(tabindex)) {
                    numerator.attr('tabindex', tabindex++);
                    denominator.attr('tabindex', tabindex);
                }
            }
            var fract = $('<div class="fraction"></div>');
            fract.attr('id', baseId + '-' + elementKey);
            fract.append([numerator, $('<hr/>'), denominator]);
            return fract;
        },
        _buildValues: function () {
            var num1 = this._getRandomFractionValue();
            var den1 = this._getRandomFractionValue();
            var num2 = this._getRandomFractionValue();
            var den2 = this._getRandomFractionValue();
            var op = Math.floor(Math.random() * 4);
            this._setFractionValues('fract1', num1, den1);
            this._setFractionValues('fract2', num2, den2);
            this._setFractionValues('fractRes', '', '');
            this._setOperandInt(op);
        },
        _verify: function () {
            if ($('.fraction-value:not([readonly])', $('#' + this.options.id)).filter(function () { return this.value.trim() === ''; }).length === 0) {
                var values = this._getValues();
                if(!isNaN(values.val1.numerator) && !isNaN(values.val1.denominator) 
                    && !isNaN(values.val2.numerator) && !isNaN(values.val2.denominator)
                    && !isNaN(values.result.numerator) && !isNaN(values.result.denominator)) {
                    var result = null;
                    var resNum = NaN, resDen = NaN;
                    switch (values.operand) {
                        case '+':
                            resNum = values.val1.numerator * values.val2.denominator + values.val2.numerator * values.val1.denominator;
                            resDen = values.val1.denominator * values.val2.denominator;
                            break;
                        case '-':
                            resNum = values.val1.numerator * values.val2.denominator - values.val2.numerator * values.val1.denominator;
                            resDen = values.val1.denominator * values.val2.denominator;
                            break;
                        case '*':
                            resNum = values.val1.numerator * values.val2.numerator;
                            resDen = values.val1.denominator * values.val2.denominator;
                            break;
                        case '/':
                            resNum = values.val1.numerator * values.val2.denominator;
                            resDen = values.val1.denominator * values.val2.numerator;
                            break;
                    }
                    if (!isNaN(resNum) && !isNaN(resDen)) {
                        if (resNum == values.result.numerator && resDen === values.result.denominator) {
                            result = true;
                        }
                        else {
                            var numLcm = window.lcm(resNum, values.result.numerator);
                            var denomLcm = window.lcm(resDen, values.result.denominator);
                            var numFactor = numLcm / Math.min(Math.abs(resNum), Math.abs(values.result.numerator));
                            var denomFactor = denomLcm / Math.min(Math.abs(resDen), Math.abs(values.result.denominator));
                            result = numFactor === denomFactor;
                        }
                    }

                    if (typeof (result) !== "undefined") {
                        if (result) {
                            $('#' + this.options.id + '-trainer-result').removeClass('glyphicon-remove-sign').addClass('glyphicon-ok-sign');
                        }
                        else {
                            $('#' + this.options.id + '-trainer-result').addClass('glyphicon-remove-sign').removeClass('glyphicon-ok-sign');
                        }
                    }
                }
            }
        },
        _getValues: function () {
            return {
                "operand": $('#' + this.options.id + '-operand').data('operand'),
                "val1": this._getFractionValues('fract1'),
                "val2": this._getFractionValues('fract2'),
                "result": this._getFractionValues('fractRes')
            };
        },
        _getFractionValues: function(elemKey) {
            return {
                "numerator": parseInt($('#' + this.options.id + '-' + elemKey + '-num').val()),
                "denominator": parseInt($('#' + this.options.id + '-' + elemKey + '-denom').val())
            };
        },
        _setFractionValues: function(elemKey, num, denom) {
            $('#' + this.options.id + '-' + elemKey + '-num').val(num);
            $('#' + this.options.id + '-' + elemKey + '-denom').val(denom);
        },
        _setOperandInt: function(operand) {
            var operandVal = '';
            var operandText = '';
            switch (operand) {
                case 0:
                    operandVal = '+';
                    operandText = '&plus;';
                    break;
                case 1:
                    operandVal = '-';
                    operandText = '&minus;';
                    break;
                case 2:
                    operandVal = '*';
                    operandText = '&times;';
                    break;
                case 3:
                    operandVal = '/';
                    operandText = '&divide;';
                    break;
            }
            if (operandVal !== '') {
                $('#' + this.options.id + '-operand').html(operandText).data('operand', operandVal);
            }
        },
        _getRandomFractionValue: function() {
            return Math.floor(Math.random() * 20 + 1);
        }
    });
    $('.lms-fraction-trainer.auto-bind:not(.widget-ready)').fractionTrainer();
    $(document).on('click', '.lms-fraction-trainer.click-bind', function () {
        $(this).fractionTrainer();
    });
})(jQuery);