(function ($, global)
{
  editor = null,
  defaultStyleList = defStyleList();
  styleList = [],
  styleWidgets = {
    styleItem: '<div class="style-editor-item" styleName="{styleName}" styleValue="{styleValue}">{styleName}:{input}</div>',
    defaultInput: '<input class="style-editor-input-item" styleName="{styleName}" type="{styleType}" value="{styleValue}"/>',
    advancedEditorInput: '<div class="style-editor-input-div"><input class="advanced-editor-input" placeholder="eg color:red; " type="text"/><i class="stylename-verifier-icon"></i></div>',
    advancedEditorAddStyle: '<button class="btn btn-primary btn-sm advanced-editor-add-style">Add</button>'
  },
    easyEditor = {
      editorParts: [{ name: "main", title: "Main" }, { name: "border", title: "Border" }, { name: "content", title: "Content" }],
      editorPartsContent: [
        { styleName: "border", styleType: "text", part: "border" },
        { styleName: "border-radius", styleType: "range", part: "border" },
        { styleName: "color", styleType: "color", part: "content" },
        { styleName: "background", styleType: "color", part: "main" },
      ]
    }
  function CamelToSnake(string)
  {
    return string.replace(/([a-z]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
  };
  var _styleEditor = function (selectedElement, target)
  {
    target.html('');
    var styleNames = getAvailabeStyles(selectedElement);
    var advancedEditor = document.createElement('div');
    var advancedEditorInputEl = styleWidgets.advancedEditorInput;
    var advancedEditorAddStyleEl = styleWidgets.advancedEditorAddStyle;
    var autoCompleteRelativeDiv = document.createElement('div');
    autoCompleteRelativeDiv.classList.add('styleEditor-autocomplete-relative-predictions-div');
    advancedEditor.innerHTML += (advancedEditorInputEl + " " + advancedEditorAddStyleEl);
    target.html(advancedEditor);
    target.append(autoCompleteRelativeDiv);
    var styleEditorList = document.createElement('div');
    
    var selectedElementStyle = (selectedElement.getAttribute('style') || "").replaceAll('/[\n\z]+/', '');
    var stylesArray = selectedElementStyle.split(';');
    stylesArray.forEach(function(style){
      style = style.split(':');
      var styleItemDiv = document.createElement('div');
      var boldText = document.createElement('b');
      var italicText = document.createElement('i');
      var styleName = style[0] || "";
      var styleValue = style[1] || "";

      var deleteIcon = document.createElement('i');
      deleteIcon.classList.add('fa');
      deleteIcon.classList.add('fa-trash');
      deleteIcon.classList.add('delete-style-btn');
      $(deleteIcon).attr('styleName',styleName.trim());

      var editStyleIcon = document.createElement('i');
      editStyleIcon.classList.add('fa');
      editStyleIcon.classList.add('fa-pencil');
      editStyleIcon.classList.add('edit-style-btn');
      $(editStyleIcon).attr('styleName',styleName.trim());
      $(editStyleIcon).attr('styleValue',styleValue.trim());


      if(styleName.trim() != "" && styleValue.trim() != ""){
        boldText.innerText = styleName + ": ";
        italicText.innerText = styleValue + ";"; 
        styleItemDiv.appendChild(boldText)
        styleItemDiv.appendChild(italicText);
        styleItemDiv.appendChild(deleteIcon);
        styleItemDiv.appendChild(editStyleIcon);
        styleEditorList.appendChild(styleItemDiv);
      }

    })
    /*var easyEditorStyles = easyEditor.editorPartsContent;
    console.log(easyEditorStyles.editorPartsContent);
    styleEditorList.innerHTML = "";
    easyEditorStyles.forEach(function(styleEl){
      var updatedItem = styleWidgets.styleItem;
      var inputItem = styleWidgets.defaultInput;
      
      inputItem = inputItem.replaceAll("{styleType}",styleEl.styleType);
      inputItem = inputItem.replaceAll("{styleValue}",$(selectedElement).css(styleEl.styleType));
      inputItem = inputItem.replaceAll("{styleName}",styleEl.styleName);

      updatedItem = updatedItem.replaceAll("{styleName}",styleEl.styleName)
      updatedItem = updatedItem.replaceAll("{input}",inputItem);
      styleEditorList.innerHTML += updatedItem;

    })*/
    target.append(styleEditorList);

  }
  var _createStyleItem = function (selectedElement)
  {

  }
  function getAvailabeStyles(selectedElement){
    var emptyElement = document.createElement('div');
    var defaultElement = emptyElement;
    var styleNameList = [];
    let scStyleNames = [];
    if(Object.keys(defaultElement.style).length != 0){
      styleNameList = Object.keys(defaultElement.style);
    }else{
      for(var prop in defaultElement.style){
        //styleNameList.push(prop);
      }
    }
    if(styleNameList.length == 0){
      styleNameList = this.defaultStyleList;
    }
    styleNameList.forEach(function (item)
    {
      item = CamelToSnake(item);
      scStyleNames.push(item);
    })
    if(styleList.length == 0){
      styleList = [...new Set(scStyleNames)];
    }

    return styleList;
  }
  function verifiedStyle(){
    var verifyItem = $('.stylename-verifier-icon').get(0)
    verifyItem.classList.remove('fa')
    verifyItem.classList.remove('fa-check')
    verifyItem.classList.remove('fa-xmark')
    verifyItem.classList.add('fa')
    verifyItem.classList.add('fa-check')
    verifyItem.style.color = "#21DC00";
    $('.advanced-editor-add-style').prop('disabled',false);
  }
  function wrongStyle(){
    var verifyItem = $('.stylename-verifier-icon').get(0)
    verifyItem.classList.remove('fa')
    verifyItem.classList.remove('fa-check')
    verifyItem.classList.remove('fa-xmark')
    verifyItem.classList.add('fa')
    verifyItem.classList.add('fa-xmark')
    verifyItem.style.color = "#DC0000";
    $('.advanced-editor-add-style').prop('disabled',true);
  }
  function notVerifiedStyle(){
    var verifyItem = $('.stylename-verifier-icon').get(0)
    verifyItem.classList.remove('fa')
    verifyItem.classList.remove('fa-check')
    verifyItem.classList.remove('fa-xmark')
    $('.advanced-editor-add-style').prop('disabled',true);
  }
  global.initStyleEditor = function (selectedElement, target,currentEditor)
  {

    editor = currentEditor;

    var styleNames = getAvailabeStyles(selectedElement);
    //selectedElement.css("background-color","red");

    //selectedElement.setAttribute('style', styleString.replace('/[\n\z]+/', ''));
    _styleEditor(selectedElement, target);
    $('.advanced-editor-add-style').prop('disabled',true);
    var autoCompleteRelativeDiv = $('.styleEditor-autocomplete-relative-predictions-div').get(0);
    var autoCompleteDiv = document.createElement('div');
    autoCompleteDiv.classList.add('styleEditor-autocomplete-predictions');
    $(target).off('click');
    $(target).off('keyup');
    $(target).on('keyup', function (ev)
    {

      if ($(ev.target).hasClass('advanced-editor-input'))
      {
        var inputSplit = ev.target.value.split(':');
        var input = inputSplit[0].trim();
        if (input.length > 0)
        {
          var filteredInput = styleNames.filter(x => x.startsWith(input));
          autoCompleteDiv.innerHTML = "";
          autoCompleteRelativeDiv.innerHTML = "";
          filteredInput.forEach(function (pred)
          {
            var predItem = document.createElement('div');
            predItem.classList.add('styleEditor-autocomplete-prediction-item');
            predItem.innerText = pred;
            autoCompleteDiv.appendChild(predItem);
          })
          notVerifiedStyle();
          if(input.length > 0){
            //eşleşen bir stil varsa doğrulandı ikonunu göster
            if (styleNames.filter(x => x == input).length == 1)
            {
              verifiedStyle();
            //eğer doğru tahmin yoksa hata göster
            } else if (filteredInput.length == 0)
            {
              wrongStyle();
            //iki nokta koyulduktan sonra hiçbir eşleşme yoksa hata göster
            }else if(inputSplit.length > 1){
              if(filteredInput.filter(x=>x == input).length == 0){
                wrongStyle();
              }
            }
          }
          // iki nokta (:) eklendikten sonra tahmin yapma
          if(inputSplit.length == 1){
            $('.styleEditor-autocomplete-relative-predictions-div').html('');
            $('.styleEditor-autocomplete-relative-predictions-div').append(autoCompleteDiv);
            //target.append(autoCompleteRelativeDiv);
          }else{
            $('.styleEditor-autocomplete-relative-predictions-div').html('');
          }

        }else{
          $('.styleEditor-autocomplete-relative-predictions-div').html('');
          notVerifiedStyle();
        }
      }

    })
    $(target).on('click', function (btn)
    {
      if($(btn.target).hasClass('styleEditor-autocomplete-prediction-item')){
        $('.advanced-editor-input').val(btn.target.innerText + ": ");
        verifiedStyle();
        $('.styleEditor-autocomplete-relative-predictions-div').remove();
        $('.advanced-editor-input').focus();
      }else if($(btn.target).hasClass('advanced-editor-add-style')){
        var currentInput = $('.advanced-editor-input');
        if(currentInput.val().trim().endsWith(';') == false){
          currentInput.val(currentInput.val().trim()+";");
        }
        var splittedInput = currentInput.val().split(':');
        var styleName = splittedInput[0].trim();
        var styleValue = splittedInput[1].trim().split(';')[0].trim();

        $(selectedElement).css(styleName,styleValue);

        currentInput.val('');
        editor.updateTargetEditor();
        editor.updateEditorTools();
      }else if($(btn.target).hasClass('delete-style-btn')){
        var styleName = btn.target.getAttribute('styleName');
        $(selectedElement).css(styleName,'');
        editor.updateTargetEditor();
        editor.updateEditorTools();
      }else if($(btn.target).hasClass('edit-style-btn')){
        var styleName = btn.target.getAttribute('styleName');
        var styleVal = btn.target.getAttribute('styleValue');
        var currentInput = $('.advanced-editor-input');
        currentInput.val(styleName + ": "+styleVal+";");
        $('.advanced-editor-input').focus();
      }
    })
  }
  function defStyleList(selectedElement){
    return [
      "accentColor",
      "additiveSymbols",
      "alignContent",
      "alignItems",
      "alignSelf",
      "alignmentBaseline",
      "all",
      "animation",
      "animationComposition",
      "animationDelay",
      "animationDirection",
      "animationDuration",
      "animationFillMode",
      "animationIterationCount",
      "animationName",
      "animationPlayState",
      "animationTimingFunction",
      "appRegion",
      "appearance",
      "ascentOverride",
      "aspectRatio",
      "backdropFilter",
      "backfaceVisibility",
      "background",
      "backgroundAttachment",
      "backgroundBlendMode",
      "backgroundClip",
      "backgroundColor",
      "backgroundImage",
      "backgroundOrigin",
      "backgroundPosition",
      "backgroundPositionX",
      "backgroundPositionY",
      "backgroundRepeat",
      "backgroundRepeatX",
      "backgroundRepeatY",
      "backgroundSize",
      "basePalette",
      "baselineShift",
      "baselineSource",
      "blockSize",
      "border",
      "borderBlock",
      "borderBlockColor",
      "borderBlockEnd",
      "borderBlockEndColor",
      "borderBlockEndStyle",
      "borderBlockEndWidth",
      "borderBlockStart",
      "borderBlockStartColor",
      "borderBlockStartStyle",
      "borderBlockStartWidth",
      "borderBlockStyle",
      "borderBlockWidth",
      "borderBottom",
      "borderBottomColor",
      "borderBottomLeftRadius",
      "borderBottomRightRadius",
      "borderBottomStyle",
      "borderBottomWidth",
      "borderCollapse",
      "borderColor",
      "borderEndEndRadius",
      "borderEndStartRadius",
      "borderImage",
      "borderImageOutset",
      "borderImageRepeat",
      "borderImageSlice",
      "borderImageSource",
      "borderImageWidth",
      "borderInline",
      "borderInlineColor",
      "borderInlineEnd",
      "borderInlineEndColor",
      "borderInlineEndStyle",
      "borderInlineEndWidth",
      "borderInlineStart",
      "borderInlineStartColor",
      "borderInlineStartStyle",
      "borderInlineStartWidth",
      "borderInlineStyle",
      "borderInlineWidth",
      "borderLeft",
      "borderLeftColor",
      "borderLeftStyle",
      "borderLeftWidth",
      "borderRadius",
      "borderRight",
      "borderRightColor",
      "borderRightStyle",
      "borderRightWidth",
      "borderSpacing",
      "borderStartEndRadius",
      "borderStartStartRadius",
      "borderStyle",
      "borderTop",
      "borderTopColor",
      "borderTopLeftRadius",
      "borderTopRightRadius",
      "borderTopStyle",
      "borderTopWidth",
      "borderWidth",
      "bottom",
      "boxShadow",
      "boxSizing",
      "breakAfter",
      "breakBefore",
      "breakInside",
      "bufferedRendering",
      "captionSide",
      "caretColor",
      "clear",
      "clip",
      "clipPath",
      "clipRule",
      "color",
      "colorInterpolation",
      "colorInterpolationFilters",
      "colorRendering",
      "colorScheme",
      "columnCount",
      "columnFill",
      "columnGap",
      "columnRule",
      "columnRuleColor",
      "columnRuleStyle",
      "columnRuleWidth",
      "columnSpan",
      "columnWidth",
      "columns",
      "contain",
      "containIntrinsicBlockSize",
      "containIntrinsicHeight",
      "containIntrinsicInlineSize",
      "containIntrinsicSize",
      "containIntrinsicWidth",
      "container",
      "containerName",
      "containerType",
      "content",
      "contentVisibility",
      "counterIncrement",
      "counterReset",
      "counterSet",
      "cursor",
      "cx",
      "cy",
      "d",
      "descentOverride",
      "direction",
      "display",
      "dominantBaseline",
      "emptyCells",
      "fallback",
      "fill",
      "fillOpacity",
      "fillRule",
      "filter",
      "flex",
      "flexBasis",
      "flexDirection",
      "flexFlow",
      "flexGrow",
      "flexShrink",
      "flexWrap",
      "float",
      "floodColor",
      "floodOpacity",
      "font",
      "fontDisplay",
      "fontFamily",
      "fontFeatureSettings",
      "fontKerning",
      "fontOpticalSizing",
      "fontPalette",
      "fontSize",
      "fontStretch",
      "fontStyle",
      "fontSynthesis",
      "fontSynthesisSmallCaps",
      "fontSynthesisStyle",
      "fontSynthesisWeight",
      "fontVariant",
      "fontVariantAlternates",
      "fontVariantCaps",
      "fontVariantEastAsian",
      "fontVariantLigatures",
      "fontVariantNumeric",
      "fontVariationSettings",
      "fontWeight",
      "forcedColorAdjust",
      "gap",
      "grid",
      "gridArea",
      "gridAutoColumns",
      "gridAutoFlow",
      "gridAutoRows",
      "gridColumn",
      "gridColumnEnd",
      "gridColumnGap",
      "gridColumnStart",
      "gridGap",
      "gridRow",
      "gridRowEnd",
      "gridRowGap",
      "gridRowStart",
      "gridTemplate",
      "gridTemplateAreas",
      "gridTemplateColumns",
      "gridTemplateRows",
      "height",
      "hyphenateCharacter",
      "hyphenateLimitChars",
      "hyphens",
      "imageOrientation",
      "imageRendering",
      "inherits",
      "initialLetter",
      "initialValue",
      "inlineSize",
      "inset",
      "insetBlock",
      "insetBlockEnd",
      "insetBlockStart",
      "insetInline",
      "insetInlineEnd",
      "insetInlineStart",
      "isolation",
      "justifyContent",
      "justifyItems",
      "justifySelf",
      "left",
      "letterSpacing",
      "lightingColor",
      "lineBreak",
      "lineGapOverride",
      "lineHeight",
      "listStyle",
      "listStyleImage",
      "listStylePosition",
      "listStyleType",
      "margin",
      "marginBlock",
      "marginBlockEnd",
      "marginBlockStart",
      "marginBottom",
      "marginInline",
      "marginInlineEnd",
      "marginInlineStart",
      "marginLeft",
      "marginRight",
      "marginTop",
      "marker",
      "markerEnd",
      "markerMid",
      "markerStart",
      "mask",
      "maskType",
      "mathDepth",
      "mathShift",
      "mathStyle",
      "maxBlockSize",
      "maxHeight",
      "maxInlineSize",
      "maxWidth",
      "minBlockSize",
      "minHeight",
      "minInlineSize",
      "minWidth",
      "mixBlendMode",
      "negative",
      "objectFit",
      "objectPosition",
      "objectViewBox",
      "offset",
      "offsetDistance",
      "offsetPath",
      "offsetRotate",
      "opacity",
      "order",
      "orphans",
      "outline",
      "outlineColor",
      "outlineOffset",
      "outlineStyle",
      "outlineWidth",
      "overflow",
      "overflowAnchor",
      "overflowClipMargin",
      "overflowWrap",
      "overflowX",
      "overflowY",
      "overrideColors",
      "overscrollBehavior",
      "overscrollBehaviorBlock",
      "overscrollBehaviorInline",
      "overscrollBehaviorX",
      "overscrollBehaviorY",
      "pad",
      "padding",
      "paddingBlock",
      "paddingBlockEnd",
      "paddingBlockStart",
      "paddingBottom",
      "paddingInline",
      "paddingInlineEnd",
      "paddingInlineStart",
      "paddingLeft",
      "paddingRight",
      "paddingTop",
      "page",
      "pageBreakAfter",
      "pageBreakBefore",
      "pageBreakInside",
      "pageOrientation",
      "paintOrder",
      "perspective",
      "perspectiveOrigin",
      "placeContent",
      "placeItems",
      "placeSelf",
      "pointerEvents",
      "position",
      "prefix",
      "quotes",
      "r",
      "range",
      "resize",
      "right",
      "rotate",
      "rowGap",
      "rubyPosition",
      "rx",
      "ry",
      "scale",
      "scrollBehavior",
      "scrollMargin",
      "scrollMarginBlock",
      "scrollMarginBlockEnd",
      "scrollMarginBlockStart",
      "scrollMarginBottom",
      "scrollMarginInline",
      "scrollMarginInlineEnd",
      "scrollMarginInlineStart",
      "scrollMarginLeft",
      "scrollMarginRight",
      "scrollMarginTop",
      "scrollPadding",
      "scrollPaddingBlock",
      "scrollPaddingBlockEnd",
      "scrollPaddingBlockStart",
      "scrollPaddingBottom",
      "scrollPaddingInline",
      "scrollPaddingInlineEnd",
      "scrollPaddingInlineStart",
      "scrollPaddingLeft",
      "scrollPaddingRight",
      "scrollPaddingTop",
      "scrollSnapAlign",
      "scrollSnapStop",
      "scrollSnapType",
      "scrollbarGutter",
      "shapeImageThreshold",
      "shapeMargin",
      "shapeOutside",
      "shapeRendering",
      "size",
      "sizeAdjust",
      "speak",
      "speakAs",
      "src",
      "stopColor",
      "stopOpacity",
      "stroke",
      "strokeDasharray",
      "strokeDashoffset",
      "strokeLinecap",
      "strokeLinejoin",
      "strokeMiterlimit",
      "strokeOpacity",
      "strokeWidth",
      "suffix",
      "symbols",
      "syntax",
      "system",
      "tabSize",
      "tableLayout",
      "textAlign",
      "textAlignLast",
      "textAnchor",
      "textCombineUpright",
      "textDecoration",
      "textDecorationColor",
      "textDecorationLine",
      "textDecorationSkipInk",
      "textDecorationStyle",
      "textDecorationThickness",
      "textEmphasis",
      "textEmphasisColor",
      "textEmphasisPosition",
      "textEmphasisStyle",
      "textIndent",
      "textOrientation",
      "textOverflow",
      "textRendering",
      "textShadow",
      "textSizeAdjust",
      "textTransform",
      "textUnderlineOffset",
      "textUnderlinePosition",
      "top",
      "touchAction",
      "transform",
      "transformBox",
      "transformOrigin",
      "transformStyle",
      "transition",
      "transitionDelay",
      "transitionDuration",
      "transitionProperty",
      "transitionTimingFunction",
      "translate",
      "unicodeBidi",
      "unicodeRange",
      "userSelect",
      "vectorEffect",
      "verticalAlign",
      "viewTransitionName",
      "visibility",
      "webkitAlignContent",
      "webkitAlignItems",
      "webkitAlignSelf",
      "webkitAnimation",
      "webkitAnimationDelay",
      "webkitAnimationDirection",
      "webkitAnimationDuration",
      "webkitAnimationFillMode",
      "webkitAnimationIterationCount",
      "webkitAnimationName",
      "webkitAnimationPlayState",
      "webkitAnimationTimingFunction",
      "webkitAppRegion",
      "webkitAppearance",
      "webkitBackfaceVisibility",
      "webkitBackgroundClip",
      "webkitBackgroundOrigin",
      "webkitBackgroundSize",
      "webkitBorderAfter",
      "webkitBorderAfterColor",
      "webkitBorderAfterStyle",
      "webkitBorderAfterWidth",
      "webkitBorderBefore",
      "webkitBorderBeforeColor",
      "webkitBorderBeforeStyle",
      "webkitBorderBeforeWidth",
      "webkitBorderBottomLeftRadius",
      "webkitBorderBottomRightRadius",
      "webkitBorderEnd",
      "webkitBorderEndColor",
      "webkitBorderEndStyle",
      "webkitBorderEndWidth",
      "webkitBorderHorizontalSpacing",
      "webkitBorderImage",
      "webkitBorderRadius",
      "webkitBorderStart",
      "webkitBorderStartColor",
      "webkitBorderStartStyle",
      "webkitBorderStartWidth",
      "webkitBorderTopLeftRadius",
      "webkitBorderTopRightRadius",
      "webkitBorderVerticalSpacing",
      "webkitBoxAlign",
      "webkitBoxDecorationBreak",
      "webkitBoxDirection",
      "webkitBoxFlex",
      "webkitBoxOrdinalGroup",
      "webkitBoxOrient",
      "webkitBoxPack",
      "webkitBoxReflect",
      "webkitBoxShadow",
      "webkitBoxSizing",
      "webkitClipPath",
      "webkitColumnBreakAfter",
      "webkitColumnBreakBefore",
      "webkitColumnBreakInside",
      "webkitColumnCount",
      "webkitColumnGap",
      "webkitColumnRule",
      "webkitColumnRuleColor",
      "webkitColumnRuleStyle",
      "webkitColumnRuleWidth",
      "webkitColumnSpan",
      "webkitColumnWidth",
      "webkitColumns",
      "webkitFilter",
      "webkitFlex",
      "webkitFlexBasis",
      "webkitFlexDirection",
      "webkitFlexFlow",
      "webkitFlexGrow",
      "webkitFlexShrink",
      "webkitFlexWrap",
      "webkitFontFeatureSettings",
      "webkitFontSmoothing",
      "webkitHighlight",
      "webkitHyphenateCharacter",
      "webkitJustifyContent",
      "webkitLineBreak",
      "webkitLineClamp",
      "webkitLocale",
      "webkitLogicalHeight",
      "webkitLogicalWidth",
      "webkitMarginAfter",
      "webkitMarginBefore",
      "webkitMarginEnd",
      "webkitMarginStart",
      "webkitMask",
      "webkitMaskBoxImage",
      "webkitMaskBoxImageOutset",
      "webkitMaskBoxImageRepeat",
      "webkitMaskBoxImageSlice",
      "webkitMaskBoxImageSource",
      "webkitMaskBoxImageWidth",
      "webkitMaskClip",
      "webkitMaskComposite",
      "webkitMaskImage",
      "webkitMaskOrigin",
      "webkitMaskPosition",
      "webkitMaskPositionX",
      "webkitMaskPositionY",
      "webkitMaskRepeat",
      "webkitMaskRepeatX",
      "webkitMaskRepeatY",
      "webkitMaskSize",
      "webkitMaxLogicalHeight",
      "webkitMaxLogicalWidth",
      "webkitMinLogicalHeight",
      "webkitMinLogicalWidth",
      "webkitOpacity",
      "webkitOrder",
      "webkitPaddingAfter",
      "webkitPaddingBefore",
      "webkitPaddingEnd",
      "webkitPaddingStart",
      "webkitPerspective",
      "webkitPerspectiveOrigin",
      "webkitPerspectiveOriginX",
      "webkitPerspectiveOriginY",
      "webkitPrintColorAdjust",
      "webkitRtlOrdering",
      "webkitRubyPosition",
      "webkitShapeImageThreshold",
      "webkitShapeMargin",
      "webkitShapeOutside",
      "webkitTapHighlightColor",
      "webkitTextCombine",
      "webkitTextDecorationsInEffect",
      "webkitTextEmphasis",
      "webkitTextEmphasisColor",
      "webkitTextEmphasisPosition",
      "webkitTextEmphasisStyle",
      "webkitTextFillColor",
      "webkitTextOrientation",
      "webkitTextSecurity",
      "webkitTextSizeAdjust",
      "webkitTextStroke",
      "webkitTextStrokeColor",
      "webkitTextStrokeWidth",
      "webkitTransform",
      "webkitTransformOrigin",
      "webkitTransformOriginX",
      "webkitTransformOriginY",
      "webkitTransformOriginZ",
      "webkitTransformStyle",
      "webkitTransition",
      "webkitTransitionDelay",
      "webkitTransitionDuration",
      "webkitTransitionProperty",
      "webkitTransitionTimingFunction",
      "webkitUserDrag",
      "webkitUserModify",
      "webkitUserSelect",
      "webkitWritingMode",
      "whiteSpace",
      "widows",
      "width",
      "willChange",
      "wordBreak",
      "wordSpacing",
      "wordWrap",
      "writingMode",
      "x",
      "y",
      "zIndex",
      "zoom"
  ]
  }

})(jQuery, window);