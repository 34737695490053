class ILayoutStorageProvider {
    getLayoutList(callback, errorCallback) { }
    getLayoutData(id, callback, errorCallback) { }
    saveLayoutData(id, name, data, saveCallback, errorCallback) { }
    getEmptyLayoutData() {
        return { id: "0", name: "", cssLinks: "", usableCssLinks: "", cssData: "", editorContent: "" };
    }
}
class IFragmentStorageProvider {
    getFragmentList(callback, errorCallback) { }
    getFragmentData(id, callback, errorCallback) { }
    saveFragmentData(id, name, data, saveCallback, errorCallback) { }
    getEmptyFragmentData() {
        return { id: "0", name: "", content: "" };
    }
}
class FragmentLocalStorageProvider extends IFragmentStorageProvider {
    dataStore = {
        'db': {},
        'store': {}
    }
    constructor(options) {
        super(options);
        let me = this;
        let indexedDb = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB ||
            window.msIndexedDB;
        Object.defineProperty(window, 'indexedDB', {
            value: indexedDb
        });
        window.IDBTransaction = window.IDBTransaction || window.webkitIDBTransaction ||
            window.msIDBTransaction;
        window.IDBKeyRange = window.IDBKeyRange ||
            window.webkitIDBKeyRange || window.msIDBKeyRange

        if (!window.indexedDB) {
            console.error('Your browser does not support IndexDB feature');
            throw new Error('Required feature not supported: IndexDB');
        }
        var request = window.indexedDB.open("savedFragments", 1);

        request.onerror = function (event) {
            console.log("error: ");
        };

        request.onsuccess = function (event) {
            me.dataStore['db'] = request.result;

        };

        request.onupgradeneeded = function (event) {
            var dbObj = event.target.result;
            var objectStore = dbObj.createObjectStore("fragment", { keyPath: "id" });
            me.dataStore['store'] = objectStore;
        };
    }
    _generateRandomId() {
        return (Math.random() + 1).toString(36).substring(2);
    }
    getFragmentList(callback, errorCallback) {
        let db = this.dataStore['db'];
        let objectStore = db.transaction("fragment").objectStore("fragment");
        let fragmentList = [];
        let req = objectStore.openCursor();
        req.onsuccess = function (event) {
            var cursor = event.target.result;

            if (cursor) {
                fragmentList.push({ id: cursor.value.id, name: cursor.value.name });
                cursor.continue();
            }
            else {
                if (typeof (callback) === 'function') {
                    callback(fragmentList);
                }
            }
        };

        req.onerror = function (event) {
            console.error(event);
            if (typeof (errorCallback) === 'function') {
                errorCallback(event);
            }
        };
    }

    getFragmentData(id, callback, errorCallback) {
        var db = this.dataStore['db'];
        if (db == null) {
            if (typeof (errorCallback) === 'function') {
                errorCallback('DB error');
            }
            return;
        }
        var transaction = db.transaction(["fragment"]);
        var objectStore = transaction.objectStore("fragment");
        var request = objectStore.get(id);

        request.onerror = function (event) {
            if (typeof (errorCallback) === 'function') {
                errorCallback(event);
            }
        };

        request.onsuccess = function (event) {
            if (typeof (callback) === 'function') {
                //let fragmentData 
                callback(request.result);
            }
        };
    }

    saveFragmentData(id, name, data, saveCallback, errorCallback) {
        var db = this.dataStore['db']
        if (db == null) {
            return;
        }
        let fragmentData = {
            id: id || this._generateRandomId(), name: name, content:data.content
        };
        var request = db.transaction(["fragment"], "readwrite").objectStore("fragment").add(fragmentData);
        request.onerror = function (event) {
            console.log(event)
            if (typeof (errorCallback) === 'function') {
                errorCallback(event);
            }
        }
        request.onsuccess = function (event) {
            console.log(event);
            if (typeof (saveCallback) === 'function') {
                saveCallback(event);
            }
        }
    }
}

class LayoutLocalStorageProvider extends ILayoutStorageProvider {
    dataStore = {
        'db': {},
        'store': {}
    };

    constructor(options) {
        super(options);
        let me = this;
        let indexedDb = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB ||
            window.msIndexedDB;
        Object.defineProperty(window, 'indexedDB', {
            value: indexedDb
        });
        window.IDBTransaction = window.IDBTransaction || window.webkitIDBTransaction ||
            window.msIDBTransaction;
        window.IDBKeyRange = window.IDBKeyRange ||
            window.webkitIDBKeyRange || window.msIDBKeyRange

        if (!window.indexedDB) {
            console.error('Your browser does not support IndexDB feature');
            throw new Error('Required feature not supported: IndexDB');
        }
        var request = window.indexedDB.open("savedLayouts", 1);

        request.onerror = function (event) {
            console.log("error: ");
        };

        request.onsuccess = function (event) {
            me.dataStore['db'] = request.result;

        };

        request.onupgradeneeded = function (event) {
            var dbObj = event.target.result;
            var objectStore = dbObj.createObjectStore("layout", { keyPath: "id" });
            me.dataStore['store'] = objectStore;
        }
    }

    _generateRandomId() {
        return (Math.random() + 1).toString(36).substring(2);
    }

    getLayoutList(callback, errorCallback) {
        let db = this.dataStore['db'];
        let objectStore = db.transaction("layout").objectStore("layout");
        let layoutList = [];
        let req = objectStore.openCursor();
        req.onsuccess = function (event) {
            var cursor = event.target.result;

            if (cursor) {
                layoutList.push({ id: cursor.value.id, name: cursor.value.name });
                cursor.continue();
            }
            else {
                if (typeof (callback) === 'function') {
                    callback(layoutList);
                }
            }
        };

        req.onerror = function (event) {
            console.error(event);
            if (typeof (errorCallback) === 'function') {
                errorCallback(event);
            }
        };
    }

    getLayoutData(id, callback, errorCallback) {
        var db = this.dataStore['db'];
        if (db == null) {
            if (typeof (errorCallback) === 'function') {
                errorCallback('DB error');
            }
            return;
        }
        var transaction = db.transaction(["layout"]);
        var objectStore = transaction.objectStore("layout");
        var request = objectStore.get(id);

        request.onerror = function (event) {
            if (typeof (errorCallback) === 'function') {
                errorCallback(event);
            }
        };

        request.onsuccess = function (event) {
            if (typeof (callback) === 'function') {
                //let layoutData 
                callback(request.result);
            }
        };
    }

    saveLayoutData(id, name, data, saveCallback, errorCallback) {
        var db = this.dataStore['db']
        if (db == null) {
            return;
        }
        let layoutData = {
            id: id || this._generateRandomId(), name: name, cssLinks: data.cssLinks,
            usableCssLinks: data.usableCssLinks, cssData: data.cssData, editorContent: data.editorContent
        };
        var request = db.transaction(["layout"], "readwrite").objectStore("layout").add(layoutData);
        request.onerror = function (event) {
            console.log(event)
            if (typeof (errorCallback) === 'function') {
                errorCallback(event);
            }
        }
        request.onsuccess = function (event) {
            console.log(event);
            if (typeof (saveCallback) === 'function') {
                saveCallback(event);
            }
        }
    }
}

window.layoutStorageProviders = {
    'local': LayoutLocalStorageProvider
};
window.fragmentStorageProviders = {
    'local': FragmentLocalStorageProvider
};

(function ($, global) {
    "use strict";
    $.widget("lms.layoutEditor", {
        options: {
            'styles': '',
            'usable-style-links': '',
            'layoutStorageProvider': 'local',
            'layoutStorageProviderOptions': '',
            'fragmentStorageProvider': 'local',
            'fragmentStorageProviderOptions': ''
        },
        previewOptions: {
            'showPreview': false
        },
        newBlockSettings: {
            'position': 'end'
            /**
             * start : En başa ekler
             * end : En sona ekler
             * selected-start : Seçilen bloğun üstüne ekler
             * selected-end : Seçilen bloğun altına ekler
             * selected-replace : Seçilen blok ile değiştirir
             */
        },
        layoutOptions: [],
        layoutStorageProvider: null,
        fragmentOptions: [],
        fragmentStorageProvider: null,
        data: {

        },
        widgets: {

        },
        movingObject: {
            'selectedElement': null,
            'isMoving': false
        },
        widgetTemplates: {
        },
        dataStore: {
            'db': {},
            'store': {}
        },
        featureMap: {
            'layout-container': 'style,cssClasses,showId',
            'fragment-block':'edit,remove,style,cssClasses,draggableMenu,selectedBlockText,showId,changeId,fragmentEditor',
            'title-bar': 'edit,remove,style,cssClasses,draggableMenu,selectedBlockText,showId,changeId,fragmentEditor',
            'one-column': 'edit,remove,style,cssClasses,draggableMenu,selectedBlockText,showId,changeId,fragmentEditor',
            'two-column': 'remove,style,cssClasses,selectedBlockText,showId,changeId,fragmentEditor',
            'three-column': 'remove,style,cssClasses,selectedBlockText,showId,changeId,fragmentEditor',
            'column': 'edit,style,cssClasses,draggableMenu,selectedBlockText,showId,changeId,fragmentEditor',
            'image': 'remove,style,cssClasses,draggableMenu,selectedBlockText,showId,changeId,fragmentEditor'
        },
        reservedCssClasses: ['selected', 'layout-container', 'layout-block', 'title-bar', 'two-column', 'column', 'column-1',
            'column-2', 'column-3', 'three-column', 'one-column', 'image', 'free-position'],
        usableCssClasses: [],
        saveEditorState: function () {
            let editor = this;
            editor.layoutStorageProvider.getLayoutList(function (data) {
                //console.log(data);
                editor.showLayoutSaveModal(data);
            }, function (err) {
                editor.showLayoutSaveModal([]);
            });
        },
        showModalMessage: function (title, text) {
            let editor = this;
            let warningEl = document.createElement('div');
            warningEl.innerHTML = "<span>" + text + "</span><br/><br/><button class='btn btn-danger confirmModal'>Close Modal</button>";
            editor.showModal(warningEl, '<b>' + title + '</b>', function (editFrm, modal) {
                $(warningEl).on('click', function (btn) {
                    if ($(btn.target).hasClass('confirmModal')) {
                        modal.modal('hide');
                        return false;
                    }
                });
            });
        },
        updateSelectButtons:function(){
            let editor = this;
            let widgets = editor.widgets;
            let contentArea = widgets['contentArea'];
            let templates = editor.widgetTemplates;
            $(contentArea).find('.select-btn').remove();
            var layoutBlocks = contentArea.querySelectorAll('.layout-block');
            console.log(layoutBlocks);
            for (let i = 0; i < layoutBlocks.length; i++) {
                layoutBlocks[i].prepend(templates['blockSelectBtn'].content.firstChild.cloneNode(true));
            }
            contentArea.prepend(templates['blockSelectBtn'].content.firstChild.cloneNode(true));
            editor.updateTargetEditor();
        },
        showConfirmationModal: function (title, text, okCallback, cancelCallback) {
            let editor = this;
            let warningEl = document.createElement('div');
            warningEl.innerHTML = "<span>" + text + "</span><br/><br/><button class='btn btn-danger confirmModal'>OK</button>" +
                "<button class='btn btn-cancel cancelModal'>Cancel</button>";
            editor.showModal(warningEl, '<b>' + title + '</b>', function (editFrm, modal) {
                $(warningEl).on('click', function (btn) {
                    if ($(btn.target).hasClass('confirmModal')) {
                        modal.modal('hide');
                        if (typeof (okCallback) === 'function') {
                            okCallback();
                        }
                        return false;
                    }
                    else if ($(btn.target).hasClass('cancelModal')) {
                        modal.modal('hide');
                        if (typeof (cancelCallback) === 'function') {
                            cancelCallback();
                        }
                        return false;
                    }
                });
            });
        },
        showLayoutSaveModal: function (layoutList) {
            let editor = this;
            let widgets = editor.widgets;
            let inputEl = document.createElement('div');
            console.log(layoutList);
            inputEl.innerHTML = "<span>Enter a new name to save the current editor contents as a new layout</span>" +
                "<div class='input-group' style='margin-bottom:3px;'>" +
                "<input class='form-control layoutNameInput' type='text' placeholder='Layout name'></div>" +
                "<hr><span>Or select an existing layout to overwrite</span>" +
                "<div class='input-group' style='margin-bottom:3px;'>" +
                "<select class='form-control layoutList'><option value=''>-- New Layout --</option></select></div>" +
                "<button class='btn btn-info confirmModal'>Save Layout</button>";
            editor.showModal(inputEl, '<b>Save Layout</b>', function (mdlForm, modal) {
                if (Array.isArray(layoutList)) {
                    layoutList.forEach(function (item) {
                        $('select.layoutList', mdlForm).append('<option value="' + item.id + '">' + item.name + '</option>');
                    });
                }
                $(inputEl).on('click', function (btn) {
                    if ($(btn.target).hasClass('confirmModal')) {
                        let name = $(".layoutNameInput", mdlForm).val();
                        let id = $('.layoutList', mdlForm).val();
                        // saveLayoutData(id, name, data, saveCallback, errorCallback)
                        let layoutStyle = widgets['layoutStyle'].innerHTML;
                        let editorContent = widgets['contentArea'].outerHTML;
                        let cssLinks = editor.options['styles'].split(";");
                        let usableCssLinks = editor.options['usable-style-links'].split(';');

                        let layoutData = {
                            name: name, cssLinks: cssLinks, usableCssLinks: usableCssLinks,
                            cssData: layoutStyle, editorContent: editorContent
                        };

                        editor.layoutStorageProvider.saveLayoutData(id, name, layoutData,
                            function (data) {
                                console.log(data);
                                modal.modal('hide');
                            }, function (err, messages) {
                                modal.modal('hide');
                                let errMsg = 'Error Status: ' + err + '<br>' + $.map(messages, function (m) { m + '<br/>' });
                                editor.showModalMessage("Layout Save Failed", errMsg);
                            });
                        return false;
                    }
                })
            });
        },
        showReplaceWarningModal: function (action) {
            let editor = this;
            let warning = document.createElement("div");
            warning.style.display = "inline-block";
            warning.innerHTML += '<button class="btn btn-sm btn-success btn-replace-ok">Replace</button>&nbsp;';
            warning.innerHTML += '<button class="btn btn-sm btn-primary btn-replace-cancel">Cancel</button>&nbsp;';
            editor.showModal(warning, "<b>This action is replace the selected block, changes will be lost</b>", function (frmModal, modal) {
                $(warning).on('click', function (btn) {
                    if ($(btn.target).hasClass("btn-replace-ok")) {
                        modal.modal('hide');
                        action();

                    } else if ($(btn.target).hasClass('btn-replace-cancel')) {
                        modal.modal('hide');
                    }
                })
            });
        },
        showSelectPositionBlockModal: function (selectedAction) {
            let editor = this;
            let message = document.createElement("div");
            let widgets = editor.widgets;
            let contentArea = widgets['contentArea'];
            message.style.display = "inline-block";
            message.innerHTML += '<button class="btn btn-sm btn-primary btn-position-top">Top</button>&nbsp;';
            message.innerHTML += '<button class="btn btn-sm btn-primary btn-position-bottom">Bottom</button>&nbsp;';
            if ($(contentArea).find('.selected').get(0) != null) {
                message.innerHTML += '<button class="btn btn-sm btn-primary btn-position-after-selected">After the selected block</button>&nbsp;';
                message.innerHTML += '<button class="btn btn-sm btn-primary btn-position-before-selected">Before the selected block</button>&nbsp;';
                message.innerHTML += '<button class="btn btn-sm btn-primary btn-position-replace-selected">Replace the selected block</button>';
            }

            editor.showModal(message, '<b>Select a position for new block</b>', function (mdlForm, modal) {
                $(message).on('click', function (btn) {
                    if ($(btn.target).hasClass("btn-position-top")) {
                        editor.newBlockSettings['position'] = "start";
                        modal.modal('hide');
                        selectedAction();

                    } else if ($(btn.target).hasClass("btn-position-bottom")) {
                        editor.newBlockSettings['position'] = "end";
                        modal.modal('hide');
                        selectedAction();

                    } else if ($(btn.target).hasClass("btn-position-after-selected")) {
                        editor.newBlockSettings['position'] = "selected-end";
                        modal.modal('hide');
                        selectedAction();

                    } else if ($(btn.target).hasClass("btn-position-before-selected")) {
                        editor.newBlockSettings['position'] = "selected-start";
                        modal.modal('hide');
                        selectedAction();

                    } else if ($(btn.target).hasClass("btn-position-replace-selected")) {
                        editor.newBlockSettings['position'] = "selected-replace";
                        modal.modal('hide');
                        editor.showReplaceWarningModal(selectedAction);

                    }

                })
            });
        },
        addItemToContainer: function (block) {
            let editor = this;
            let position = editor.newBlockSettings['position'];
            let widgets = editor.widgets;
            let contentArea = widgets['contentArea'];
            switch (position) {
                case 'start':
                    $(block).insertAfter($(contentArea).find('.select-btn').get(0))
                    break;
                case 'end':
                    contentArea.appendChild(block);
                    break;
                case 'selected-start':
                    console.log($(contentArea).find('.layout-block.selected').get(0));
                    $(block).insertBefore($(contentArea).find('.selected'));
                    break;
                case 'selected-end':
                    $(block).insertAfter($(contentArea).find('.selected'))
                    break;
                case 'selected-replace':
                    $(contentArea).find('.layout-block.selected').replaceWith(block);
                    break;
                default:
                    break;
            }
            editor.updateTargetEditor();
            editor.assignRandomIdIfHaveLayoutBlockClass();
        },
        /*-----------------------*/
        generateRandomId: function () {
            var rd = (Math.random() + 1).toString(36).substring(2);
            return rd;
        },
        assignRandomIdIfHaveLayoutBlockClass: function () {
            let editor = this;
            let contentArea = editor.widgets['contentArea'];
            let layoutBlocks = contentArea.querySelectorAll('.layout-block');
            console.log(layoutBlocks)
            layoutBlocks.forEach(function (layoutBlock) {
                let randomId = editor.generateRandomId();
                let blockId = $(layoutBlock).attr("data-block-id");
                console.log(blockId);
                if (blockId == "" || blockId == undefined) {
                    layoutBlock.setAttribute('data-block-id', randomId);
                }
            })
            editor.updatePreview();
            editor.updateSelectBlockElement();
        },
        getFragments:function(){
            let editor = this;
            editor.fragmentStorageProvider.getFragmentList(function(result){
                console.log(result);
                editor._createFragmentLoader(result);
            },function(err){
                console.log(err);
            })
        },
        getFragmentsForContainer:function(){
            let editor = this;
            editor.fragmentStorageProvider.getFragmentList(function(result){
                console.log(result);
                editor._createFragmentLoader(result,false);
            },function(err){
                console.log(err);
            })
        },
        saveFragmentFromSelectedBlock: function () {
            let editor = this;
            let contentArea = editor.widgets['contentArea'];
            let selectedBlock = $(contentArea).find('.selected');

            var copiedBlock = selectedBlock.get(0).cloneNode(true);
            copiedBlock.classList.remove("selected");
            $(copiedBlock).find('.select-btn')[0].remove();
            let onlyContent;
            let withOuter;
            let selectedBlockCode;

            withOuter = copiedBlock.outerHTML;

            onlyContent = copiedBlock.innerHTML;
  
            let saveFragmentBody = document.createElement("div");
            let saveFragmentLabel = document.createElement("label");
            saveFragmentLabel.innerText ="Fragment Name";
            let saveFragmentInput = document.createElement("input");
            saveFragmentInput.type = "text";
            saveFragmentInput.classList.add("form-control");
            let saveFragmentWithContainerCheckLabel = document.createElement("label");
            saveFragmentWithContainerCheckLabel.innerText = "Save fragment with outer block ";
            let saveFragmentWithContainerCheck = document.createElement("input");
            saveFragmentWithContainerCheck.type = "checkbox";
  
            let saveFragmentButton = document.createElement("button");
            saveFragmentButton.classList.add("btn","btn-primary","save-fragment-btn");
            saveFragmentButton.innerText = "Save";
            saveFragmentButton.style.marginTop = "5px";
            saveFragmentWithContainerCheckLabel.appendChild(saveFragmentWithContainerCheck);
            saveFragmentBody.appendChild(saveFragmentLabel);
            saveFragmentBody.appendChild(saveFragmentInput);
            saveFragmentBody.appendChild(saveFragmentWithContainerCheckLabel);
            saveFragmentBody.appendChild(saveFragmentButton);
            editor.showModal(saveFragmentBody,"Save Fragment",function(frmModal,modal){
                $(saveFragmentBody).on('click',function(btn){
                    if($(btn.target).hasClass('save-fragment-btn')){
                        var withOuterElementCheck = saveFragmentWithContainerCheck.checked;
                        console.log(editor.fragmentStorageProvider);

                        if(saveFragmentInput.value == "" || saveFragmentInput.value == undefined){
                            modal.modal('hide');
                            editor.showModalMessage("An Error Occurred","Fragment name can't be null");
                            return;
                        }
                        editor.fragmentStorageProvider.saveFragmentData("",saveFragmentInput.value,{content:withOuterElementCheck == true ? withOuter : onlyContent},function(success){
                            console.log("Kayıt edildi");
                            modal.modal('hide');
                            editor.showModalMessage("Success","Fragment is saved with "+saveFragmentInput.value+" name");
                        },function(err){
                            console.log("Kayıt başarısız");
                            modal.modal('hide');
                            editor.showModalMessage("An Error Occurred","An error occurred while saving the fragment");
                        })
                    }
                })
            })

        },
        assignIdFromSelectedBlock: function (id) {
            let editor = this;
            let contentArea = editor.widgets['contentArea'];
            let selectedElement = contentArea.querySelector('.layout-block.selected');
            $(selectedElement).attr('data-block-id', id);
            editor.updatePreview();
            editor.updateEditorTools();
            editor.updateSelectBlockElement();
        },
        updateSelectBlockElement: function () {
            let editor = this;
            let widgets = editor.widgets;
            let options = editor.options;
            let previewArea = editor.widgets['previewContent'];
            let contentArea = editor.widgets['contentArea'];
            let previewBody = $(previewArea).contents().find('body');
            let layoutBlocks = previewBody.get(0).querySelectorAll('.layout-block');

            $('.layoutBlockSelector').html('');
            let option = document.createElement('option');
            $(option).attr('disabled', 'disabled');
            $(option).attr('selected', 'selected');
            option.innerText = '-------------';
            $('.layoutBlockSelector').get(0).appendChild(option);

            let layoutContainerOption = document.createElement('option');
            layoutContainerOption.value = 'layout-container';
            layoutContainerOption.innerText = 'Layout Container';
            $('.layoutBlockSelector').get(0).appendChild(layoutContainerOption);

            layoutBlocks.forEach(function (block) {
                let option = document.createElement('option');
                option.value = $(block).attr('data-block-id');
                option.innerText = $(block).attr('data-block-id');
                $('.layoutBlockSelector').get(0).appendChild(option);
            })

        },
        showRenameModal: function () {
            let div = document.createElement('div');
            let input = document.createElement('input');
            input.placeholder = "Please input a new block id";
            input.classList.add('input-newblockid');
            input.style.height = "30px";
            input.style.fontSize = "17px";
            input.style.padding = "5px";

            let btn = document.createElement('button');
            btn.classList.add('btn', 'btn-warning', 'changeBlockIdBtn');
            btn.innerText = "Change Block Id"

            div.appendChild(input);
            div.innerHTML += "<br/><br/>";
            div.appendChild(btn);
            editor.showModal(div, "Change Block Id", function (changeBlockIdFrm, modal) {
                btn.addEventListener('click', function () {
                    let newId = $('.input-newblockid').val();
                    editor.assignIdFromSelectedBlock(newId);
                    modal.modal('hide');
                })
            }, function () { });
        },
        /*-----------------------*/
        remove: function (layoutName) {
            var editor = this;
            var db = editor.dataStore['db'];
            if (db == null) {
                //alert("DBError");
                return;
            }
            var request = db.transaction(["layout"], "readwrite")
                .objectStore("layout")
                .delete(layoutName);

            request.onsuccess = function (event) {
                editor.showModalMessage("Layout Loader", layoutName + "has been deleted");
            };
        },
        _createLayoutLoader(layoutNames, warning = false) {
            let editor = this;
            let widgets = editor.widgets;
            let editorTools = widgets['editorTools'];

            $(editorTools).find('.editor-tool-panel').css('display', 'none');
            $(editorTools).find('.layout-loader').remove();
            var layoutsDiv = document.createElement('div');
            layoutsDiv.classList.add('layout-loader')
            layoutsDiv.innerHTML = '<b style="font-size:17px;"><i class="fas fa-table"></i> Select Layout</b><br/>';
            layoutNames.forEach(function (layoutName) {
                var layoutNameDiv = document.createElement("div");
                layoutNameDiv.innerText = layoutName.name;
                layoutNameDiv.classList.add('layout-select-name');
                layoutNameDiv.setAttribute('data-id', layoutName.id);
                layoutsDiv.appendChild(layoutNameDiv);
            });
            editorTools.prepend(layoutsDiv);
            console.log(layoutsDiv)
        },
        _createFragmentLoader: function(fragmentNames,selectedBlock=true){
            let editor = this;
            let widgets = editor.widgets;
            let editorTools = widgets['editorTools'];

            $(editorTools).find('.editor-tool-panel').css('display', 'none');
            $(editorTools).find('.fragment-loader').remove();
            var fragmentsDiv = document.createElement('div');
            fragmentsDiv.classList.add('fragment-loader')
            fragmentsDiv.innerHTML = '<b style="font-size:17px;"><i class="fas fa-table"></i> Select Fragment</b><br/>';
            fragmentNames.forEach(function (fragmentName) {
                var fragmentNameDiv = document.createElement("div");
                fragmentNameDiv.innerText = fragmentName.name;
                fragmentNameDiv.classList.add('fragment-select-name');
                fragmentNameDiv.setAttribute('data-id', fragmentName.id);
                fragmentNameDiv.setAttribute('data-selblock',selectedBlock == true ? 'true' : 'false');
                fragmentsDiv.appendChild(fragmentNameDiv);
            });
            editorTools.prepend(fragmentsDiv);
            console.log(fragmentsDiv)
        },
        _create: function () {
            let options = this.options;
            options.targetEditor = this.element.data('target');
            let data = this.data;
            if (typeof (options.preloadData) === 'object') {

            }
            else {
                data['layoutType'] = '';
                data['layoutData'] = {
                    'regions': {},
                    'blocks': {}
                };
            }
            this.initializeTemplates();
            this.createWidgets();
            this.bindEventListeners();
            this.syncFromTargetEditor();
            if (typeof (global.layoutStorageProviders[options.layoutStorageProvider]) === 'function') {
                this.layoutStorageProvider = new global.layoutStorageProviders[options.layoutStorageProvider](options.layoutStorageProviderOptions);
                console.log(this.layoutStorageProvider);
            }
            if (typeof (global.fragmentStorageProviders[options.fragmentStorageProvider]) === 'function') {
                this.fragmentStorageProvider = new global.fragmentStorageProviders[options.fragmentStorageProvider](options.fragmentStorageProviderOptions);
                console.log(this.fragmentStorageProvider);
            }

        },
        initializeTemplates: function () {
            let templates = this.widgetTemplates;
            templates['layoutContent'] = document.createElement('template');
            templates['layoutContent'].innerHTML = '<div class="layout-container" data-block-id="layout-container" data-type="layout-container"></div>';
            templates['tSelectLayoutBlock'] = document.createElement('template');
            templates['tSelectLayoutBlock'].innerHTML = '<div><label>Select Block : </label><select class="layoutBlockSelector tool-btn"><option selected disabled>------------</option></select></div>';
            templates['tBtnEditStyle'] = document.createElement('template');
            templates['tBtnEditStyle'].innerHTML = '<a style="cursor:pointer;" class="tool-btn edit-style"><span class="fas fa-code"></span>Edit Style</a>';
            templates['tBtnAddTitleBar'] = document.createElement('template');
            templates['tBtnAddTitleBar'].innerHTML = '<a style="cursor:pointer;" class="tool-btn add-title-bar"><span class="fas fa-plus"></span>Title Bar</a>';
            templates['tBtnAddOneColBlock'] = document.createElement('template');
            templates['tBtnAddOneColBlock'].innerHTML = '<a style="cursor:pointer;" class="tool-btn add-one-col"><span class="fas fa-plus"></span>1-Column Block</a>';
            templates['tBtnAddTwoColBlock'] = document.createElement('template');
            templates['tBtnAddTwoColBlock'].innerHTML = '<a style="cursor:pointer;" class="tool-btn add-two-cols"><span class="fas fa-plus"></span>2-Column Block</a>';
            templates['tBtnAddThreeColBlock'] = document.createElement('template');
            templates['tBtnAddThreeColBlock'].innerHTML = '<a style="cursor:pointer;" class="tool-btn add-three-cols"><span class="fas fa-plus"></span>3-Column Block</a>';
            templates['tBtnAddImage'] = document.createElement('template');
            templates['tBtnAddImage'].innerHTML = '<a style="cursor:pointer;" class="tool-btn add-image"><span class="fas fa-plus"></span>Image</a>';

            templates['tBtnPreview'] = document.createElement('template');
            templates['tBtnPreview'].innerHTML = '<a style="cursor:pointer;" class="tool-btn preview-button"><i class="fa-solid fa-layer-group"></i> Preview</a>';

            templates['tBtnFragment'] = document.createElement('template');
            templates['tBtnFragment'].innerHTML = '<a style="cursor:pointer;" class="tool-btn add-fragment-button"><i class="fa-solid fa-plus-circle"></i> Fragment</a>';

            templates['tBtnSave'] = document.createElement('template');
            templates['tBtnSave'].innerHTML = '<a style="cursor:pointer;" class="tool-btn layout-save"><i class="fas fa-save"></i> Save</a>';
            templates['tBtnLoad'] = document.createElement('template');
            templates['tBtnLoad'].innerHTML = '<a style="cursor:pointer;" class="tool-btn layout-load"><i class="fas fa-refresh"></i> Load</a>';

            templates['editorTools'] = document.createElement('template');
            templates['editorTools'].innerHTML = '<div class="editor-tools">' +
                '<!--<div class="block-selector-panel"><select id="block-selector"><option value="">-- select a block --</option></select></div>-->'
                + '<div class="editor-tool-panel block-selectedBlockText"><b><i class="fa-solid fa-cube"></i> Selected Block</b></div>'
                + '<div class="block-showId editor-tool-panel"><b>Block Id :</b><font class="blockIdText"></font></div>'
                + '<div class="block-changeId editor-tool-panel"><a style="cursor:pointer;" class="changeId-btn btn"><span class="fas fa-edit"></span>Change Id</a></div>'
                + '<div class="block-edit editor-tool-panel"><b>Content <br/></b><a style="cursor:pointer;" class="edit-btn btn"><span class="fas fa-edit"></span>Edit</a></div>'
                + '<div class="block-remove editor-tool-panel"><a style="cursor:pointer;" class="remove-btn btn"><span class="fas fa-trash"></span>Remove</a></div>'
                + '<br/><div class="block-draggableMenu editor-tool-panel"><b>Draggable Block Element</b><div class="draggable-element-tool"><i class="fa-solid fa-up-down-left-right"></i><input class="draggable-allowDragCheckbox btn" type="checkbox"/> Allow Drag</div></div>'
                + '<br/><div class="block-cssClasses editor-tool-panel"><b>Block CSS Classes</b><br/><input style="display:none;" class="block-cssClasses-input"/><div class="block-cssClasses-list"></div>'
                + '<div><input class="add-new-blockClass-input" type="text" value="" placeholder="Class Name"/><button class="add-new-blockClass-button btn btn-sm btn-primary">Add</button><div class="blockClass-autocomplete-list"></div></div>'
                + '<div style="display:none;" class="editor-buttons"><a style="cursor:pointer;" class="save-cssClasses-btn btn"><span class="fas fa-save"></span>Save</a><a style="cursor:pointer;" class="discard-cssClasses-btn btn"><span class="fas fa-trash"></span>Discard</a></div></div>'
                + '<br/><div class="block-style editor-tool-panel"><b>Custom Style</b><br/><textarea class="block-style-editor" style="display:none;"></textarea><div class="block-style-editor-div"></div>'
                + '<!--<div class="editor-buttons"><a style="cursor:pointer;" class="save-style-btn btn"><span class="fas fa-save"></span>Save</a><a style="cursor:pointer;" class="discard-style-btn btn"><span class="fas fa-trash"></span>Discard</a></div></div>-->'
                + '<br/><div class="block-fragmentEditor editor-tool-panel"><b>Block Fragment</b><br/><a style="cursor:pointer;" class="save-fragment-btn btn"><span class="fas fa-save"></span>Save</a><a style="cursor:pointer;" class="load-fragment-btn btn"><span class="fas fa-refresh"></span>Load</a></div>'
                + '</div>';
            templates['previewContent'] = document.createElement('template');
            templates['previewContent'].innerHTML = '<iframe sandbox="allow-same-origin allow-scripts allow-popups allow-forms" class="preview-frame layout-container-frame"></iframe>';
            // layout elements
            templates['blockTitleBar'] = document.createElement('template');
            templates['blockTitleBar'].innerHTML = '<div class="layout-block title-bar" data-type="title-bar"></div>';
            templates['block2Column'] = document.createElement('template');
            templates['block2Column'].innerHTML = '<div class="layout-block two-column" data-type="two-column"><div class="layout-block column column-1" data-type="column"></div>'
                + '<div class="layout-block column column-2" data-type="column"></div></div>';
            templates['block3Column'] = document.createElement('template');
            templates['block3Column'].innerHTML = '<div class="layout-block three-column" data-type="three-column"><div class="layout-block column column-1" data-type="column"></div>'
                + '<div class="layout-block column column-2" data-type="column"></div>'
                + '<div class="layout-block column column-3" data-type="column"></div></div>';
            templates['block1Column'] = document.createElement('template');
            templates['block1Column'].innerHTML = '<div class="layout-block one-column" data-type="one-column"></div>';
            templates['blockImage'] = document.createElement('template');
            templates['blockImage'].innerHTML = '<div class="layout-block image" data-type="image"><img /></div>';

            // Tool UI
            templates['layoutStyleEditor'] = document.createElement('template');
            templates['layoutStyleEditor'].innerHTML = '<div class="style-editor"><div class="editor-area"><textarea class="style-code"></textarea></div>'
                + '<div class="editor-buttons"><a style="cursor:pointer;" class="save-btn"><span class="fas fa-save"></span>Save</a><a style="cursor:pointer;" class="discard-btn"><span class="fas fa-trash"></span>Discard</a></div></div>';
            templates['layoutBlockContentEditor'] = document.createElement('template');
            templates['layoutBlockContentEditor'].innerHTML = '<div class="content-editor"><div class="editor-area"><textarea class="block-code"></textarea></div>'
                + '<div class="editor-buttons"><a style="cursor:pointer;" class="save-btn"><span class="fas fa-save"></span>Save</a><a style="cursor:pointer;" class="discard-btn"><span class="fas fa-trash"></span>Discard</a></div></div>';
            templates['blockSelectBtn'] = document.createElement('template');
            templates['blockSelectBtn'].innerHTML = '<a style="cursor:pointer;" class="select-btn"><span class="fas fa-cogs"></span></a>';
            templates['addImageForm'] = document.createElement('template');
            templates['addImageForm'].innerHTML = '<div class="image-form">Select Image: <input name="BlockImageFile" type="file"/><br/>'
                + '<div class="image-params"><b>Width: </b><input name="BlockImageWidth"/> &nbsp;&nbsp;<b>Height: </b><input name="BlockImageHeight"/><br/>'
                + '<b>Style: </b><br/><textarea name="BlockImageStyle"></textarea></div>'
                + '<div class="editor-buttons"><a style="cursor:pointer;" class="save-btn"><span class="fas fa-save"></span>Save</a><a style="cursor:pointer;" class="discard-btn"><span class="fas fa-trash"></span>Discard</a></div>'
                + '</div>';
        },
        loadLayout: function (layoutId) {
            let editor = this;
            editor.layoutStorageProvider.getLayoutList(function (data) {
                editor._createLayoutLoader(data);
            }, function (err) {
                editor.showModalMessage("Error", "Unable to get layout list.");
            });
        },
        _fragmentContentControl(data){
            var mainDiv = document.createElement("div");
            var childDiv = document.createElement("content");
            childDiv.innerHTML = data.content;
            mainDiv.appendChild(childDiv)
            console.log(mainDiv);
            var items = $(mainDiv).find('content > *');
            let itemCount = items.length;
            let haveLayoutBlockItems = 0;
            items.each(function(index,item){
                console.log($(item).prop('tagName').toLowerCase());
                if($(item).prop('tagName').toLowerCase() == 'img'){
                    var parentEl = document.createElement("div");
                    parentEl.appendChild(item.cloneNode(true));
                    $(item).replaceWith($('<div class="layout-block" data-type="fragment-block">' + parentEl.innerHTML + '</div>'))
                    
                }
                if(itemCount == 1){ //tek tepe element varsa layout block ata
                    if(!$(item).hasClass('layout-block')){
                        console.log(item);
                        item.classList.add('layout-block');
                        var type = item.getAttribute('data-type');
                        if(type == null || type == ""){
                            item.setAttribute('data-type','fragment-block')
                        }
                    }
                }else{
                    if($(item).hasClass('layout-block')){
                        haveLayoutBlockItems+=1;
                    }
                }
                if($(item).hasClass('select-btn')){
                    $(item).remove();
                }
            })
            if(itemCount > 1 && haveLayoutBlockItems > 0){ //eğer item sayısı 1 den büyük ve layout block classına sahip tepe elementler varsa diğer tepe elementlere de layout-block ekle
                items.each(function(index,item){
                    if(!$(item).hasClass('layout-block')){
                        item.classList.add('layout-block');
                        var type = item.getAttribute('data-type');
                        if(type == null || type == ""){
                            item.setAttribute('data-type','fragment-block')
                        }
                    }
                });
            }
            console.log("items");
            console.log(items)
            console.log(items.length)
            console.log(data.content.length);
            if(itemCount > 1 && haveLayoutBlockItems == 0){  //item sayısı 1 den büyük ise ve hiç layout block classına sahip eleman yoksa layout-block classı olan div oluştur
                var createdDiv = document.createElement("div");
                createdDiv.classList.add("layout-block");
                createdDiv.innerHTML = childDiv.innerHTML;
                createdDiv.setAttribute('data-type','fragment-block')
                return createdDiv.outerHTML;
            }else if(items.length == 0 && data.content.length > 0){  //hiç element yoksa (yalnızca text) layout-block classı olan div oluştur
                var createdDiv = document.createElement("div");
                createdDiv.classList.add("layout-block");
                createdDiv.setAttribute('data-type','fragment-block')
                createdDiv.innerHTML = childDiv.innerHTML;
                return createdDiv.outerHTML;
            
            }else{
                console.log(childDiv.innerHTML)
                return childDiv.innerHTML;
            }

        },
        loadFragment:function(data,selBlock=true){
            let editor = this;
            let widgets = editor.widgets;
            let contentArea = editor.widgets['contentArea']
            var fragmentPositionDiv = document.createElement("div");
            if(selBlock){
                fragmentPositionDiv.innerHTML += '<label><input type="radio" name="fragment-position-radio" value="fragment-position-replace" class="fragment-position-radio"> Replace</label><br/>';
                fragmentPositionDiv.innerHTML += '<label><input type="radio" name="fragment-position-radio" value="fragment-position-append" class="fragment-position-radio"> Append</label><br/>';
                fragmentPositionDiv.innerHTML += '<label><input type="radio" name="fragment-position-radio" value="fragment-position-prepend" class="fragment-position-radio"> Prepend</label><br/>';
                fragmentPositionDiv.innerHTML += '<label><input type="radio" name="fragment-position-radio" value="fragment-position-beforeSelected" class="fragment-position-radio"> Add fragment before the selected block</label><br/>';
                fragmentPositionDiv.innerHTML += '<label><input type="radio" name="fragment-position-radio" value="fragment-position-afterSelected" class="fragment-position-radio"> Add fragment after the selected block</label><br/><br/>';
            }else{
                fragmentPositionDiv.innerHTML += '<label><input type="radio" name="fragment-position-radio" value="fragment-position-top" class="fragment-position-radio"> Top</label><br/>';
                fragmentPositionDiv.innerHTML += '<label><input type="radio" name="fragment-position-radio" value="fragment-position-bottom" class="fragment-position-radio"> Bottom</label><br/>';
            }

            fragmentPositionDiv.innerHTML += '<button class="btn btn-primary btn-sm add-fragment-btn">Load Fragment</button>';
            editor.showModal(fragmentPositionDiv,"Fragment Position",function(frmModal,modal){
                $(fragmentPositionDiv).on('click',function(btn){
                    if($(btn.target).hasClass('add-fragment-btn')){
                        var selectedValue = $(fragmentPositionDiv).find('input[name="fragment-position-radio"]:checked').val();
                        console.log(selectedValue);
                        modal.modal('hide');
                        switch(selectedValue){
                            case 'fragment-position-replace':
                                if($(contentArea).find('.selected') != undefined && $(contentArea).find('.selected')[0] != undefined){
                                    var blockContent = editor._fragmentContentControl(data);
                                    $(contentArea).find('.selected').replaceWith(blockContent);
                                    editor.updateTargetEditor();
                                    editor.updateEditorTools();
                                }
                                break;
                            case 'fragment-position-append':
                                var blockContent = data.content;
                                $(contentArea).find('.selected').append(blockContent)
                                editor.updateTargetEditor();
                                editor.updateEditorTools();
                                break;
                            case 'fragment-position-prepend':
                                var blockContent = data.content;
                                $(contentArea).find('.selected').prepend(blockContent)
                                editor.updateTargetEditor();
                                editor.updateEditorTools();
                                break;
                            case 'fragment-position-beforeSelected':
                                if($(contentArea).find('.selected') != undefined && $(contentArea).find('.selected')[0] != undefined){
                                    var blockContent = editor._fragmentContentControl(data);
                                    //$(contentArea).find('.selected').replaceWith(blockContent);
                                    $(blockContent).insertBefore($(contentArea).find('.selected'))
                                    editor.updateTargetEditor();
                                    editor.updateEditorTools();
                                }
                                break;
                            case 'fragment-position-afterSelected':
                                if($(contentArea).find('.selected') != undefined && $(contentArea).find('.selected')[0] != undefined){
                                    var blockContent = editor._fragmentContentControl(data);
                                    $(blockContent).insertAfter($(contentArea).find('.selected'))
                                    editor.updateTargetEditor();
                                    editor.updateEditorTools();
                                }
                                break;
                            case 'fragment-position-top':
                                var blockContent = editor._fragmentContentControl(data);
                                $(contentArea).prepend(blockContent)
                                editor.updateTargetEditor();
                                editor.updateEditorTools();
                                break;
                            case 'fragment-position-bottom':
                                var blockContent = editor._fragmentContentControl(data);
                                $(contentArea).append(blockContent);
                                editor.updateTargetEditor();
                                editor.updateEditorTools();
                                break;
                            default:
                                editor.showModalMessage("Fragment Loader","An Error Occurred")
                                break;
                            
                        }
                        editor.updateSelectButtons();
                        editor.assignRandomIdIfHaveLayoutBlockClass();
                    }
                })
            });
            /*if($(contentArea).find('.selected') != undefined && $(contentArea).find('.selected')[0] != undefined){
                //var blockContent = editor._layoutContentControl(data);
                //$(contentArea).find('.selected').html(blockContent);
            }*/
        },
        loadEditorState: function (layoutData) {
            let editor = this;
            let widgets = editor.widgets;
            let SavedEditorState = layoutData;
            widgets['layoutStyle'].innerHTML = SavedEditorState['cssData'] || "";
            if (SavedEditorState['editorContent']) {
                widgets['contentArea'] = $(SavedEditorState['editorContent'])[0];
            }
            //widgets['contentArea'].innerHTML = SavedEditorState['editorContent'] || "";
            editor.options['styles'] = (SavedEditorState['cssLinks'] || []).join(';');
            editor.options['usable-style-links'] = (SavedEditorState['usableCssLinks'] || []).join(';');
            editor.updateTargetEditor();
            console.log(SavedEditorState);
        },

        createWidgets: function () {
            let templates = this.widgetTemplates;
            let options = this.options;
            let widgets = this.widgets;
            if (typeof (widgets['ready']) !== 'undefined') {
                return;
            }
            let editorPanel = document.createElement('div');
            editorPanel.classList.add('layout-editor');
            if (typeof (options.id) === 'string') {
                editorPanel.id = options.id;
            }
            let editorToolbar = document.createElement('div');
            editorToolbar.classList.add('layout-editor-toolbar');
            let tSelectLayoutBlock = templates['tSelectLayoutBlock'].content.firstChild.cloneNode(true);
            editorToolbar.appendChild(tSelectLayoutBlock);
            let tBtnEditStyle = templates['tBtnEditStyle'].content.firstChild.cloneNode(true);
            editorToolbar.appendChild(tBtnEditStyle);
            let tBtnAddTitleBar = templates['tBtnAddTitleBar'].content.firstChild.cloneNode(true);
            editorToolbar.appendChild(tBtnAddTitleBar);
            let tBtnAddOneColBlock = templates['tBtnAddOneColBlock'].content.firstChild.cloneNode(true);
            editorToolbar.appendChild(tBtnAddOneColBlock);
            let tBtnAddTwoColBlock = templates['tBtnAddTwoColBlock'].content.firstChild.cloneNode(true);
            editorToolbar.appendChild(tBtnAddTwoColBlock);
            let tBtnAddThreeColBlock = templates['tBtnAddThreeColBlock'].content.firstChild.cloneNode(true);
            editorToolbar.appendChild(tBtnAddThreeColBlock);
            let tBtnAddImage = templates['tBtnAddImage'].content.firstChild.cloneNode(true);
            editorToolbar.appendChild(tBtnAddImage);

            let tBtnPreview = templates['tBtnPreview'].content.firstChild.cloneNode(true);
            editorToolbar.appendChild(tBtnPreview);

            let tBtnFragment = templates['tBtnFragment'].content.firstChild.cloneNode(true);
            editorToolbar.appendChild(tBtnFragment);

            let tBtnSaveButton = templates['tBtnSave'].content.firstChild.cloneNode(true);
            editorToolbar.appendChild(tBtnSaveButton);
            let tBtnLoadButton = templates['tBtnLoad'].content.firstChild.cloneNode(true);
            editorToolbar.appendChild(tBtnLoadButton);

            let previewFrame = templates['previewContent'].content.firstChild.cloneNode(true);
            let contentPanel = document.createElement('div');
            contentPanel.classList.add('layout-editor-content');
            let contentArea = templates['layoutContent'].content.firstChild.cloneNode(true);
            contentPanel.appendChild(previewFrame);
            let editorTools = templates['editorTools'].content.firstChild.cloneNode(true);
            contentPanel.appendChild(editorTools);
            let layoutStyle = document.createElement('style');
            layoutStyle.classList.add('layout-style');

            widgets['editorPanel'] = editorPanel;
            widgets['toolbar'] = editorToolbar;
            widgets['content'] = contentPanel;
            widgets['layoutStyle'] = layoutStyle;
            widgets['contentArea'] = contentArea;
            widgets['tBtnEditStyle'] = tBtnEditStyle;
            widgets['tBtnAddTitleBar'] = tBtnAddTitleBar;
            widgets['tBtnAddOneColBlock'] = tBtnAddOneColBlock;
            widgets['tBtnAddTwoColBlock'] = tBtnAddTwoColBlock;
            widgets['tBtnAddThreeColBlock'] = tBtnAddThreeColBlock;
            widgets['tBtnAddImage'] = tBtnAddImage;
            widgets['editorTools'] = editorTools;
            widgets['previewContent'] = previewFrame;
            editorPanel.appendChild(editorToolbar);
            editorPanel.appendChild(layoutStyle);
            previewFrame.src = 'about:blank';
            let previewBody = $(previewFrame).contents().find('body');
            previewBody.html('');
            let previewHead = $(previewFrame).contents().find('head');
            previewHead.html('');
            previewBody.css('position', "relative");
            //contentPanelFrame.srcdoc = contentPanel.innerHTML;
            //contentPanelFrame.sandbox = "";
            editorPanel.appendChild(contentPanel);
            //if (frameDoc.document)frameDoc = frameDoc.document;
            //frameDoc.appendChild(contentPanel);
            //contentPanelContainer.contentWindow.document.append(contentPanel);
            //editorPanel.appendChild(contentPanel);
            let targetEditor = $('#' + options.target);
            $(editorPanel).insertAfter(targetEditor);
            targetEditor.hide();
            this.updateEditorTools();
        },
        loadedStyles: false,
        updatePreview: function () {
            let editor = this;
            let widgets = editor.widgets;
            let options = editor.options;
            let previewOptions = editor.previewOptions;
            let contentArea = editor.widgets['contentArea']
            let previewArea = editor.widgets['previewContent']
            let previewContents = contentArea.outerHTML;
            let styleLinks = options['styles'].split(';')
            let usableStyleLinks = options['usable-style-links'].split(';')
            console.log("loadStyles" + editor.loadedStyles);
            if (editor.loadedStyles == false) {
                console.log(styleLinks)
                styleLinks.forEach(function (styleUrl) {
                    if ($(previewArea).contents().find('head').children('link[href="' + styleUrl + '"]').length > 0) return;
                    var cssLink = document.createElement("link");
                    cssLink.href = styleUrl;
                    cssLink.rel = "stylesheet";
                    cssLink.type = "text/css";
                    cssLink.crossOrigin = "anonymous";
                    $(previewArea).contents().find('head').append(cssLink);
                })
                console.log(usableStyleLinks)
                usableStyleLinks.forEach(function (styleUrl) {
                    if ($(previewArea).contents().find('head').children('link[href="' + styleUrl + '"]').length > 0) return;
                    var cssLink = document.createElement("link");
                    cssLink.href = styleUrl;
                    cssLink.rel = "stylesheet";
                    cssLink.type = "text/css";
                    cssLink.crossOrigin = "anonymous";
                    $(previewArea).contents().find('head').append(cssLink);
                })
                var styleLen = $(previewArea).contents().find('head').find('link').length;
                if (styleLen != 0) {
                    editor.loadedStyles = false;
                }

            }
            let previewBody = $(previewArea).contents().find('body');

            let previewHead = $(previewArea).contents().find('head');
            //previewBody.css('height',"100%");
            let style = $(previewHead).contents().find('style')

            if (style.length == 0) {
                style.remove();
            }
            previewHead.append(widgets['layoutStyle']);
            previewBody.html(previewContents);
            this.bindIframeEventListeners();
        },
        bindIframeEventListeners: function () {
            // ----------------------- Preview Content Selected Buttons ----------------------------------
            console.log("bind event listeners (iframe)")
            let editor = this;
            let widgets = editor.widgets;
            let editorTools = widgets['editorTools'];
            let previewArea = widgets['previewContent']
            let bodyContents = $(previewArea).contents().find('body');
            bodyContents.off('click');
            bodyContents.off('click', '.select-btn');
            bodyContents.on('click', '.select-btn', function () {
                let widgets = editor.widgets;

                let contentArea = widgets['contentArea'];


                let block = $(this).parent();

                if (block.hasClass('selected')) {
                    block.removeClass('selected');
                }
                else {

                    bodyContents.find('.layout-block.selected').removeClass('selected');
                    bodyContents.find('.layout-container.selected').removeClass('selected');
                    block.addClass('selected');
                }
                //contentArea.outerHTML = bodyContents.html();
                contentArea = bodyContents[0].firstChild.cloneNode(true);
                widgets['contentArea'] = contentArea;
                console.log('contentArea');
                console.log(contentArea);
                editor.updateEditorTools();
                return false;
            })
            let movingObject = this.movingObject;
            let currentMousePos = { x: -1, y: -1 };
            bodyContents.on('mousedown', function (ev) {
                let targetObject = null;
                if ($(ev.target).parent().hasClass('layout-block') && $(ev.target).parent().hasClass('free-position')) {
                    targetObject = $(ev.target).parent().get(0);
                } else {
                    targetObject = ev.target;
                }
                if (movingObject['isMoving'] == false) {
                    if (($(targetObject).hasClass('layout-block')) && $(targetObject).hasClass('selected') && $(targetObject).hasClass('free-position')) {
                        if ($(targetObject).css('position') != "absolute") {
                            $(targetObject).css('position', "absolute");
                        }
                        movingObject['isMoving'] = true;
                        movingObject['selectedElement'] = targetObject;


                        currentMousePos = { x: ev.clientX - previewArea.offsetLeft, y: ev.clientY - previewArea.offsetTop };
                    }
                }

            })
            bodyContents.on('mouseup', function (ev) {
                if (movingObject['isMoving'] == true) {
                    editorTools.querySelector('.block-style-editor').value = (movingObject['selectedElement'].getAttribute('style') || "").replace('/[\n\z]+/', '');
                    let contentArea = widgets['contentArea'];
                    let selectedElement = contentArea.querySelector('.layout-block.selected'); // || editorPanel.querySelector('.layout-container.selected');

                    if (selectedElement === null && contentArea.classList.contains('selected')) {
                        selectedElement = contentArea;
                    }
                    var currentVal = editorTools.querySelector('.block-style-editor').value;
                    selectedElement.setAttribute('style', currentVal)
                    editor.updateEditorTools();
                    movingObject['isMoving'] = false;
                    movingObject['selectedElement'] = null;
                }
            })
            /*bodyContents.on('mouseleave',function(ev){
                if(movingObject['isMoving'] == true){
                    editorTools.querySelector('.block-style-editor').value = (movingObject['selectedElement'].getAttribute('style') || "").replace('/[\n\z]+/', '');
                    let selectedElement = contentArea.querySelector('.layout-block.selected') || editorPanel.querySelector('.layout-container.selected');
                    var currentVal = editorTools.querySelector('.block-style-editor').value;
                    selectedElement.setAttribute('style',currentVal)
                    editor.updateEditorTools();
                    movingObject['isMoving'] = false;
                    movingObject['selectedElement'] = null;
                    console.log("leave")
                }
            })*/


            bodyContents.on('mousemove', function (ev) {
                if (movingObject['isMoving'] == true && movingObject['selectedElement'] != null) {
                    var posX = ev.clientX - bodyContents.get(0).scrollLeft;
                    var posY = ev.clientY + bodyContents.get(0).scrollTop;
                    let selectedElement = bodyContents.get(0).querySelector('.layout-block.selected') || bodyContents.get(0).querySelector('.layout-container.selected');
                    if (Math.abs(currentMousePos.x - posX) > 5 || Math.abs(currentMousePos.y - posY) > 5) {
                        //console.log(ev.clientY - previewArea.offsetTop + previewArea.scrollTop)
                        var ch = selectedElement.clientHeight / 2;
                        var cw = selectedElement.clientWidth / 2;
                        currentMousePos = { x: posX, y: posY };
                        $(movingObject['selectedElement']).css("top", posY - ch);
                        $(movingObject['selectedElement']).css("left", posX - cw);

                    }
                }
            })

            // ----------------------- End Code -----------------------------
        },
        bindEventListeners: function () {
            let editor = this;
            let templates = editor.widgetTemplates;
            let featureMap = editor.featureMap;
            let widgets = editor.widgets;
            let editorTools = widgets['editorTools'];
            let editorPanel = widgets['editorPanel'];
            $(editorTools).on('keyup', function (keypressEvent) {

                if ($(keypressEvent.target).hasClass("add-new-blockClass-input")) {
                    var currentInput = $(".add-new-blockClass-input")[0].value;
                    var filtered = editor.usableCssClasses.filter(x => x.startsWith(currentInput));
                    var listDiv = document.createElement("div");
                    listDiv.classList.add("blockClass-autocomplete-sublist");
                    filtered.forEach(function (prediction) {
                        var item = document.createElement("div")
                        item.classList.add("blockClass-autocomplete-item");
                        item.classList.add("btn");
                        var strongItem = document.createElement("strong");
                        var writed = currentInput;
                        var pred = prediction.substring(writed.length);
                        strongItem.innerText = writed;
                        item.appendChild(strongItem);
                        $(item).attr("autocomplete-data", prediction);
                        item.innerHTML += "<font>" + pred + "</font>"
                        listDiv.appendChild(item);
                    })
                    if (currentInput != "") {
                        $(".blockClass-autocomplete-list").html("");
                        $(".blockClass-autocomplete-list").append(listDiv);
                    } else {
                        $(".blockClass-autocomplete-list").html("");
                    }
                    if (filtered.length == 1) {
                        if (currentInput == filtered[0]) {
                            $(".blockClass-autocomplete-list").html("");
                        }
                    }

                }
            })

            $(editorTools).on('click', '.editor-tool-panel .btn', function () {

                let widgets = editor.widgets;
                let contentArea = widgets['contentArea'];

                let selectedElement = contentArea.querySelector('.layout-block.selected'); // || editorPanel.querySelector('.layout-container.selected');
                if (selectedElement === null && contentArea.classList.contains('selected')) {
                    selectedElement = contentArea;
                }
                if (selectedElement === null) {
                    editor.updateEditorTools();
                }
                else {
                    let elementType = $(selectedElement).data('type');
                    let editorFeatures = (featureMap[elementType] || '').split(',');
                    let btn = $(this);
                    if (btn.hasClass('edit-btn') && editorFeatures.indexOf('edit') !== -1) {
                        let blockContentEditor = templates['layoutBlockContentEditor'].content.firstChild.cloneNode(true);
                        let codeEditor = blockContentEditor.querySelector('textarea');
                        let tmpContent = document.createElement('template');
                        tmpContent.innerHTML = selectedElement.innerHTML;
                        let selectBtns = tmpContent.content.querySelectorAll('.select-btn');
                        for (let i = 0; i < selectBtns.length; i++) {
                            selectBtns[i].remove();
                        }
                        codeEditor.value = tmpContent.innerHTML;

                        editor.showModal(blockContentEditor, 'Edit Block Contents', function (editFrm, modal) {
                            if (typeof (global.initSummernote) === 'function') {
                                global.initSummernote($(codeEditor), {
                                    dialogsInBody: true
                                });
                            }
                            // todo: bind monaco editor
                            $(blockContentEditor).on('click', '.save-btn', function () {
                                console.log(codeEditor.value);
                                selectedElement.innerHTML = codeEditor.value;
                                var layoutBlocks = selectedElement.querySelectorAll('.layout-block');
                                for (let i = 0; i < layoutBlocks.length; i++) {
                                    layoutBlocks[i].prepend(templates['blockSelectBtn'].content.firstChild.cloneNode(true));
                                }
                                selectedElement.prepend(templates['blockSelectBtn'].content.firstChild.cloneNode(true));
                                //layoutStyle.innerHTML = codeEditor.value;
                                modal.modal('hide');
                                editor.updateTargetEditor();
                                return false;
                            });
                            $(blockContentEditor).on('click', '.discard-btn', function () {
                                modal.modal('hide');
                                return false;
                            });
                        });
                    }
                    else if (btn.hasClass('remove-btn') && editorFeatures.indexOf('remove') !== -1) {
                        selectedElement.remove();
                    }
                    else if (btn.hasClass('save-style-btn') && editorFeatures.indexOf('style') !== -1) {
                        let styleString = editorTools.querySelector('.block-style-editor').value;
                        // todo: css validation

                        selectedElement.setAttribute('style', styleString.replace('/[\n\z]+/', ''));
                        //editor.updateTargetEditor();
                    } else if (btn.hasClass('blockClass-autocomplete-item')) {
                        var autocompleteData = $(btn).attr("autocomplete-data");

                        $(".add-new-blockClass-input")[0].value = autocompleteData;
                        $(".blockClass-autocomplete-list").html("");
                    }
                    else if (btn.hasClass('discard-style-btn') && editorFeatures.indexOf('style') !== -1) {
                        editorTools.querySelector('.block-style-editor').value = selectedElement.getAttribute('style') || '';
                    }
                    else if (btn.hasClass('save-cssClasses-btn') && editorFeatures.indexOf('cssClasses') !== -1) {
                        let cssClassEditor = editorTools.querySelector('.block-cssClasses-input');
                        let classString = cssClassEditor.value;

                        let preservedClasses = (cssClassEditor.getAttribute('data-preserved-classes') || '').trim().split(',');
                        selectedElement.setAttribute('class', classString);
                        selectedElement.classList.add(...preservedClasses);
                    }
                    else if (btn.hasClass('discard-cssClasses-btn') && editorFeatures.indexOf('cssClasses') !== -1) {
                        let cssClassEditor = editorTools.querySelector('.block-cssClasses-input');
                        cssClassEditor.value = selectedElement.classList.value;

                    } else if (btn.hasClass('delete-class-btn')) {
                        let cssClassEditor = editorTools.querySelector('.block-cssClasses-input');
                        let currentClasses = cssClassEditor.value.split(' ');
                        let prevElement = $(btn).prev()
                        currentClasses = currentClasses.filter(p => p.trim() != prevElement[0].innerText.trim());
                        cssClassEditor.value = currentClasses.join(' ');
                        prevElement.remove();
                        $(btn).remove();
                        editor.saveCssClasses();

                        return false;
                    } else if (btn.hasClass("add-new-blockClass-button")) {

                        let addInput = $('.add-new-blockClass-input')[0];
                        if (addInput.value.trim() != "") {
                            var usableCssClasses = editor.usableCssClasses;
                            var filter = usableCssClasses.filter(x => x == addInput.value);
                            if (filter.length > 0) {
                                let cssClassEditor = editorTools.querySelector('.block-cssClasses-input');
                                cssClassEditor.value += ' ' + addInput.value.trim();
                                let cssClassList = cssClassEditor.value.split(' ')
                                addInput.value = "";
                                editor.saveCssClasses();
                                editor.updateBlockClassList(cssClassList);
                                editor.saveCssClasses();
                                return false;
                            } else {
                                alert("Please check the class name");
                            }

                        }

                    } else if (btn.hasClass('advanced-editor-add-style')) {
                        return false;
                    } else if (btn.hasClass('draggable-allowDragCheckbox')) {
                        $(selectedElement).toggleClass('free-position');
                        bodyContents = $(previewArea).contents().find('body')
                        $(bodyContents).find('.layout-block.selected').toggleClass('free-position');
                        return;
                    } else if (btn.hasClass('changeId-btn')) {
                        editor.showRenameModal();
                        return;
                    } else if (btn.hasClass('save-fragment-btn')) {
                        editor.saveFragmentFromSelectedBlock();
                    } else if (btn.hasClass('load-fragment-btn')) {
                        editor.getFragments();
                    }
                    editor.updateEditorTools();
                    editor.updateTargetEditor();
                }
                return false;
            });
            $(editorTools).on('click', '.layout-loader .layout-select-name', function (btn) {
                let layoutName = btn.target.innerText;
                let layoutId = btn.target.getAttribute('data-id');
                editor.showConfirmationModal("Layout Loader Warning", "If you haven't saved the layout, your changes will be lost.",
                    function () {
                        editor.layoutStorageProvider.getLayoutData(layoutId, function (data) {
                            if (data) {
                                editor.loadEditorState(data);
                                editor.updateEditorTools();
                                editor.updateTargetEditor();
                            }
                            else {
                                editor.showModalMessage("Layout Loader Error", "Unable to find layout with id:" + layoutId);
                            }
                        }, function (err) {
                            editor.showModal('Layout Loader Error', 'Could not load layout data');
                        })
                    });
            })
            $(editorTools).on('click', '.fragment-loader .fragment-select-name', function (btn) {
                let fragmentName = btn.target.innerText;
                let fragmentId = btn.target.getAttribute('data-id');
                let selectedBlock = btn.target.getAttribute('data-selblock');
                editor.fragmentStorageProvider.getFragmentData(fragmentId, function (data) {
                    if (data) {
                        if(selectedBlock == 'true'){
                            editor.loadFragment(data,true);
                            //editor.loadEditorState(data);
                            editor.updateEditorTools();
                            editor.updateTargetEditor();
                        }else{
                            editor.loadFragment(data,false);
                            //editor.loadEditorState(data);
                            editor.updateEditorTools();
                            editor.updateTargetEditor();
                        }

                    }
                    else {
                        editor.showModalMessage("Layout Loader Error", "Unable to find layout with id:" + layoutId);
                    }
                }, function (err) {
                    editor.showModal('Layout Loader Error', 'Could not load layout data');
                })
            })
            $(editorPanel).on('click', '.select-btn', function () {
                let widgets = editor.widgets;

                let contentArea = widgets['contentArea'];


                let block = $(this).parent();
                if (block.hasClass('selected')) {
                    block.removeClass('selected');
                }
                else {
                    $('.layout-editor .layout-block.selected').removeClass('selected');
                    $('.layout-editor .layout-container.selected').removeClass('selected');
                    block.addClass('selected');
                }
                editor.updateEditorTools();
                return false;
            });
            // ----------------------- Preview Content Selected Buttons ----------------------------------

            let previewArea = widgets['previewContent']
            let bodyContents = $(previewArea).contents().find('body');
            bodyContents.on('click', '.select-btn', function () {
                let widgets = editor.widgets;

                let contentArea = widgets['contentArea'];


                let block = $(this).parent();

                if (block.hasClass('selected')) {
                    block.removeClass('selected');
                }
                else {

                    $(bodyContents).find('.layout-block.selected').removeClass('selected');
                    $(bodyContents).find('.layout-container.selected').removeClass('selected');
                    block.addClass('selected');
                }
                //contentArea.outerHTML = $(bodyContents).html();
                contentArea = bodyContents[0].firstChild.cloneNode(true);
                widgets['contentArea'] = contentArea;
                editor.updateEditorTools();
                return false;
            })

            // ----------------------- End Code -----------------------------
            $(editorPanel).on('change', '.layout-editor-toolbar .tool-btn', function (ev) {
                let widgets = editor.widgets;
                let contentArea = widgets['contentArea'];
                let previewArea = widgets['previewContent']
                let btn = $(this);
                console.log(btn.get(0));
                if (btn.hasClass('layoutBlockSelector')) {
                    let val = btn.val();
                    let obj = null;
                    if (val == "layout-container") {
                        obj = $(contentArea);
                    } else {
                        obj = $(contentArea).find("[data-block-id='" + val + "']");
                    }

                    $(contentArea).find('.layout-block.selected').removeClass('selected');
                    $(contentArea).removeClass('selected');
                    obj.get(0).classList.add('selected');
                    editor.updatePreview();
                    editor.updateEditorTools();
                }
            })
            $(editorPanel).on('click', '.layout-editor-toolbar .tool-btn', function () {
                let widgets = editor.widgets;
                let contentArea = widgets['contentArea'];
                let previewArea = widgets['previewContent']

                let btn = $(this);
                if (btn.hasClass('edit-style')) {
                    let styleEditor = templates['layoutStyleEditor'].content.firstChild.cloneNode(true);
                    let layoutStyle = widgets['layoutStyle'];
                    let codeEditor = styleEditor.querySelector('textarea');
                    codeEditor.value = layoutStyle.innerHTML;
                    let monacoEditor = null;
                    editor.showModal(styleEditor, 'Edit Layout Style', function (editFrm, modal) {
                        if (typeof monaco !== 'undefined') {
                            codeEditor.outerHTML = '<div class="style-code"></div>';
                            setTimeout(function () {
                                monacoEditor = monaco.editor.create(document.getElementsByClassName('style-code')[0], {
                                    language: 'css',
                                    value: layoutStyle.innerHTML,
                                    theme: 'vs',
                                    autoIndent: true,
                                    tabSize: 2,
                                    automaticLayout: true
                                });
                            }, 50)
                        }
                        // todo: bind monaco editor
                        $(styleEditor).on('click', '.save-btn', function () {

                            if (typeof monaco !== 'undefined') {
                                layoutStyle.innerHTML = monacoEditor.getValue();
                            } else {
                                layoutStyle.innerHTML = codeEditor.value;
                            }

                            modal.modal('hide');
                            editor.updateUsableClassList();
                            editor.updateTargetEditor();
                            return false;
                        });
                        $(styleEditor).on('click', '.discard-btn', function () {
                            modal.modal('hide');
                            return false;
                        });
                    });
                }
                else if (btn.hasClass('add-title-bar')) {
                    let addTitleBar = function () {
                        let titleBar = templates['blockTitleBar'].content.firstChild.cloneNode(true);
                        titleBar.innerText = 'Enter title here';
                        var layoutBlocks = titleBar.querySelectorAll('.layout-block');
                        for (let i = 0; i < layoutBlocks.length; i++) {
                            layoutBlocks[i].prepend(templates['blockSelectBtn'].content.firstChild.cloneNode(true));
                        }
                        titleBar.prepend(templates['blockSelectBtn'].content.firstChild.cloneNode(true));
                        //contentArea.appendChild(titleBar);
                        //editor.updateTargetEditor();
                        editor.addItemToContainer(titleBar);
                    }
                    editor.showSelectPositionBlockModal(addTitleBar);

                }
                else if (btn.hasClass('add-fragment-button')) {
                    editor.getFragmentsForContainer();
                }
                else if (btn.hasClass('preview-button')) {
                    let previewOptions = editor.previewOptions;
                    if (previewOptions.showPreview) {
                        //close preview

                        let previewArea = widgets['previewContent']
                        let bodyContents = $(previewArea).contents().find('body');
                        $(bodyContents).find('.select-btn').css('display', 'block');
                        previewOptions.showPreview = false;
                        return;
                        //editor.updatePreview();
                    } else {
                        //show preview
                        let previewArea = widgets['previewContent']
                        let bodyContents = $(previewArea).contents().find('body');
                        $(bodyContents).find('.select-btn').css('display', 'none');
                        previewOptions.showPreview = true;
                        return;
                        //editor.updatePreview();

                    }

                }
                else if (btn.hasClass('layout-save')) {
                    editor.saveEditorState();
                }
                else if (btn.hasClass('layout-load')) {
                    editor.loadLayout();
                }
                else if (btn.hasClass('add-one-col')) {
                    let addOneColumnBlock = function () {
                        let block = templates['block1Column'].content.firstChild.cloneNode(true);
                        block.innerText = 'Enter content here';
                        var layoutBlocks = block.querySelectorAll('.layout-block');
                        for (let i = 0; i < layoutBlocks.length; i++) {
                            layoutBlocks[i].prepend(templates['blockSelectBtn'].content.firstChild.cloneNode(true));
                        }
                        block.prepend(templates['blockSelectBtn'].content.firstChild.cloneNode(true));
                        //contentArea.appendChild(block);
                        //editor.updateTargetEditor();
                        editor.addItemToContainer(block);
                    }
                    editor.showSelectPositionBlockModal(addOneColumnBlock);

                }
                else if (btn.hasClass('add-two-cols')) {
                    let addTwoColumnBlock = function () {
                        let block = templates['block2Column'].content.firstChild.cloneNode(true);
                        block.querySelector('.column-1').innerText = 'Enter content here';
                        block.querySelector('.column-2').innerText = 'Enter content here';
                        var layoutBlocks = block.querySelectorAll('.layout-block');
                        for (let i = 0; i < layoutBlocks.length; i++) {
                            layoutBlocks[i].prepend(templates['blockSelectBtn'].content.firstChild.cloneNode(true));
                        }
                        block.prepend(templates['blockSelectBtn'].content.firstChild.cloneNode(true));
                        //contentArea.appendChild(block);
                        //editor.updateTargetEditor();
                        editor.addItemToContainer(block);
                    }
                    editor.showSelectPositionBlockModal(addTwoColumnBlock);

                }
                else if (btn.hasClass('add-three-cols')) {
                    let addThreeColumnBlock = function () {
                        let block = templates['block3Column'].content.firstChild.cloneNode(true);
                        block.querySelector('.column-1').innerText = 'Enter content here';
                        block.querySelector('.column-2').innerText = 'Enter content here';
                        block.querySelector('.column-3').innerText = 'Enter content here';
                        var layoutBlocks = block.querySelectorAll('.layout-block');
                        for (let i = 0; i < layoutBlocks.length; i++) {
                            layoutBlocks[i].prepend(templates['blockSelectBtn'].content.firstChild.cloneNode(true));
                        }
                        block.prepend(templates['blockSelectBtn'].content.firstChild.cloneNode(true));
                        //contentArea.appendChild(block);
                        //editor.updateTargetEditor();
                        editor.addItemToContainer(block);
                    }
                    editor.showSelectPositionBlockModal(addThreeColumnBlock)

                }
                else if (btn.hasClass('add-image')) {
                    let addImageBlock = function () {
                        let imageAddForm = templates['addImageForm'].content.firstChild.cloneNode(true);
                        editor.showModal(imageAddForm, 'Add Image', function (iForm, modal) {
                            $(imageAddForm).on('click', '.save-btn', function () {
                                let imgWidthFld = imageAddForm.querySelector('[name="BlockImageWidth"]');
                                let imgHeightFld = imageAddForm.querySelector('[name="BlockImageHeight"]');
                                let imgStyleFld = imageAddForm.querySelector('[name="BlockImageStyle"]');
                                let imgFld = imageAddForm.querySelector('[name="BlockImageFile"]');
                                if (imgFld.files.length > 0) {
                                    let imgFile = imgFld.files[0];
                                    const imgReader = new FileReader();
                                    //let canvas = document.createElement('canvas');
                                    //let ctx = canvas.getContext('2d');
                                    imgReader.onload = async (event) => {
                                        let img = new Image();
                                        img.onload = function () {
                                            let ratio = 100 / Math.max(img.width, img.height);
                                            let w = -1;
                                            let h = -1;
                                            let block = templates['blockImage'].content.firstChild.cloneNode(true);
                                            let imgElem = block.querySelector('img');
                                            imgElem.src = event.target.result;
                                            if (!isNaN(parseInt(imgWidthFld.value))) {
                                                w = parseInt(imgWidthFld.value);
                                            }
                                            if (!isNaN(parseInt(imgHeightFld.value))) {
                                                h = parseInt(imgHeightFld.value);
                                            }

                                            let imgStyle = '';
                                            if (imgStyleFld.value.trim() !== '') {
                                                imgStyle += imgStyleFld.value.trim().replace('\n', '').replace('\r', '');
                                            }
                                            if (!imgStyle.endsWith(';')) {
                                                imgStyle += ';';
                                            }
                                            if (w === -1 && h === -1) {
                                                w = img.width;
                                                h = img.height;
                                            }
                                            else if (h === -1) {
                                                h = Math.floor(w * img.width / img.height);
                                            }
                                            else if (w === -1) {
                                                w = Math.floor(h / (img.width / img.height));
                                            }
                                            imgStyle = 'width:' + w + 'px;height:' + h + 'px;' + imgStyle;
                                            imgElem.setAttribute('style', imgStyle);
                                            var layoutBlocks = block.querySelectorAll('.layout-block');
                                            for (let i = 0; i < layoutBlocks.length; i++) {
                                                layoutBlocks[i].prepend(templates['blockSelectBtn'].content.firstChild.cloneNode(true));
                                            }
                                            block.prepend(templates['blockSelectBtn'].content.firstChild.cloneNode(true));
                                            editor.addItemToContainer(block);
                                            modal.modal('hide');
                                            //contentArea.appendChild(block);

                                            //editor.updateTargetEditor();
                                        };
                                        img.src = event.target.result;
                                        setTimeout(function () {
                                            editor.assignRandomIdIfHaveLayoutBlockClass();
                                        }, 50);
                                    }
                                    imgReader.readAsDataURL(imgFile);
                                    /*console.log(codeEditor.value);
                                    layoutStyle.innerHTML = codeEditor.value;
                                    modal.modal('hide');
                                    editor.updateTargetEditor();*/
                                }

                                return false;
                            });
                            $(imageAddForm).on('click', '.discard-btn', function () {
                                modal.modal('hide');
                                return false;
                            });
                        });
                    }
                    editor.showSelectPositionBlockModal(addImageBlock);
                }
                editor.assignRandomIdIfHaveLayoutBlockClass();
                return false;
            });

        },
        updateUsableClassList: function () {
            let editor = this;
            let widgets = editor.widgets;
            let layoutStyle = widgets['layoutStyle'];
            let previewArea = widgets['previewContent']
            let previewHead = $(previewArea).contents().find('head')
            let styles = $(previewHead).find('style')
            let previewDoc = $(previewArea).contents().get(0);

            let styleSheets = previewDoc.styleSheets;
            editor.usableCssClasses = [];
            var sheetLength = previewDoc.styleSheets.length;
            for (var i = 0; i < sheetLength; i++) {

                let styleHref = previewDoc.styleSheets[i].href;
                if (styleHref !== null) {
                    styleHref = new URL(styleHref).pathname;
                }

                if (editor.options["usable-style-links"].indexOf(styleHref) !== -1 || styleHref === null) {
                    let layoutStyleParse = parseCss(previewDoc.styleSheets[i]);
                    editor.usableCssClasses = editor.usableCssClasses.concat(layoutStyleParse);
                }


            }

            //})
            editor.usableCssClasses = [...new Set(editor.usableCssClasses)];

            return 0;
        },
        saveCssClasses: function () {
            let widgets = this.widgets;
            let editorPanel = widgets['editorPanel'];
            let editorTools = widgets['editorTools'];
            let contentArea = widgets['contentArea'];

            let selectedElement = contentArea.querySelector('.layout-block.selected'); // || editorPanel.querySelector('.layout-container.selected');
            if (selectedElement === null && contentArea.classList.contains('selected')) {
                selectedElement = contentArea;
            }
            if (selectedElement === null) {
                editor.updateEditorTools();
            }
            let cssClassEditor = editorTools.querySelector('.block-cssClasses-input');
            let classString = cssClassEditor.value;

            let preservedClasses = (cssClassEditor.getAttribute('data-preserved-classes') || '').trim().split(',');
            selectedElement.setAttribute('class', classString);
            selectedElement.classList.add(...preservedClasses);
            this.updateEditorTools();
            this.updateTargetEditor();
        },
        showModal: function (element, title, initCallback, destroyCallback) {
            title = title || '';
            var html = '<div id="formModal" class="modal faled" tabindex="-1" role="dialog" aria-labelledby="confirm-modal" aria-hidden="true">'
                + '<div class="modal-dialog modal-dialog-customSize"><div class="modal-content">'
                + '<div class="modal-header">' + title + '<a class="close modalCloseBtn" data-dismiss="modal" data-bs-dismiss="modal">x</a></div>'
                + '<div class="modal-body">'
                + '</div>'
                + '<div class="modal-footer"></div>'
                + '</div></div>'
                + '</div>';
            $("body").append(html);
            $('#formModal .modal-body')[0].appendChild(element);
            if (typeof (initCallback) === 'function') {
                initCallback(element, $('#formModal'));
            }
            $("#formModal").modal();
            $("#formModal").modal("show");
            $('#formModal').on('hidden.bs.modal', function (e) {
                if (typeof (destroyCallback) === 'function') {
                    destroyCallback(element, $('#formModal'));
                }
                $(this).remove();
            });
        },
        updateEditorTools: function () {

            let editor = this;
            let widgets = this.widgets;
            let editorPanel = widgets['editorPanel'];
            $(editorPanel).find('.layout-loader').remove();
            $(editorPanel).find('.fragment-loader').remove();
            let templates = this.widgetTemplates;
            let featureMap = this.featureMap;
            let contentArea = widgets['contentArea'];
            let editorTools = widgets['editorTools'];
            let previewArea = widgets['previewContent']
            let bodyContents = $(previewArea).contents().find('body');
            $("iframe").contents().find("body").css('height', $("iframe").contents().find("body").get(0).scrollHeight + "px");
            //console.log($("iframe").contents().find("body").get(0));
            let selectedElement = contentArea.querySelector('.layout-block.selected'); // || editorPanel.querySelector('.layout-container.selected');
            if (selectedElement === null && contentArea.classList.contains('selected')) {
                selectedElement = contentArea;
            }
            editor.updateUsableClassList();
            if (selectedElement === null) {
                $('.editor-tool-panel', $(editorTools)).hide();
            }
            else {
                let tools = $(editorTools);
                let elementType = $(selectedElement).data('type');

                $('.editor-tool-panel', tools).hide();
                try {
                    let editorFeatures = (featureMap[elementType] || '').split(',');
                    for (let i = 0; i < editorFeatures.length; i++) {
                        $('.editor-tool-panel.block-' + editorFeatures[i], tools).show();
                    }
                    if (editorFeatures.indexOf('style') !== -1) {
                        // todo: css tidy
                        $('.block-style-editor', tools).val(selectedElement.getAttribute('style') || '');
                        global.initStyleEditor(selectedElement, $('.block-style-editor-div'), this);
                        editor.updateTargetEditor();


                    }
                    if (editorFeatures.indexOf('showId') !== -1) {
                        $('.blockIdText').get(0).innerText = $(selectedElement).attr('data-block-id');
                    }
                    if (editorFeatures.indexOf('cssClasses') !== -1) {
                        let selectedElementClassList = (selectedElement.classList.value || '').split(' ');

                        $('.block-cssClasses-input', tools).val(selectedElement.classList.value || '');
                        this.updateBlockClassList(selectedElementClassList);
                        let preservedClasses = [];
                        for (const c of selectedElement.classList) {
                            if (this.reservedCssClasses.indexOf(c) !== -1) {
                                preservedClasses.push(c);
                            }
                        }
                        $('.block-cssClasses-input', tools).attr('data-preserved-classes', preservedClasses.join(','));
                    }
                    if (editorFeatures.indexOf('draggableMenu') !== -1) {
                        let selectedElementClassList = (selectedElement.classList.value || '').split(' ');
                        let freePos = selectedElementClassList.filter(x => x.trim() == "free-position");
                        if (freePos.length != 0) {
                            $(".draggable-allowDragCheckbox").get(0).checked = true;
                        } else {
                            $(".draggable-allowDragCheckbox").get(0).checked = false;
                        }
                    }
                }
                catch (e) {
                    $('.editor-tool-panel', tools).hide();
                }
            }
        },
        updateBlockClassList: function (classListArray) {

            let classListBlock = $('.block-cssClasses-list');
            classListBlock.html('')
            classListArray.forEach(function (className) {
                if (className != "free-position") {
                    var classInputDiv = document.createElement('div');
                    var classNameText = document.createElement("p");
                    var deleteIconA = document.createElement('a');
                    $(classNameText).css("display", "inline")
                    $(classNameText).css("margin-right", "2px");
                    classNameText.innerText = className;
                    var deleteIcon = document.createElement('i');
                    deleteIconA.classList.add("btn", "delete-class-btn");
                    deleteIcon.classList.add("fas", "fa-trash");
                    deleteIconA.append(deleteIcon);
                    classInputDiv.appendChild(classNameText);
                    classInputDiv.append(deleteIconA);
                    classListBlock.append(classInputDiv);
                }

            })
        },
        updateTargetEditor: function () {
            let editor = this;
            let options = editor.options;
            let widgets = editor.widgets;
            let contentArea = widgets['contentArea'];
            let layoutStyle = widgets['layoutStyle'];
            let targetEditor = document.getElementById(options.target);
            if (targetEditor !== null) {
                let tmpContent = document.createElement('template');
                tmpContent.innerHTML = contentArea.outerHTML;
                let selectedElems = tmpContent.content.firstChild.querySelectorAll('.selected');
                for (let i = 0; i < selectedElems.length; i++) {
                    selectedElems[i].classList.remove('selected');
                }
                tmpContent.content.firstChild.classList.remove('selected');
                let selectBtns = tmpContent.content.firstChild.querySelectorAll('.select-btn');
                for (let i = 0; i < selectBtns.length; i++) {
                    selectBtns[i].remove();
                }
                targetEditor.value = (layoutStyle.outerHTML || '') + '\n\n' + tmpContent.innerHTML;
            }
            this.updatePreview();
        },
        syncFromTargetEditor: function () {
            let editor = this;
            let options = editor.options;
            let targetEditor = document.getElementById(options.target);
            if (targetEditor.value.trim() !== '') {
                let widgets = editor.widgets;
                let templates = editor.widgetTemplates;
                let contentArea = widgets['contentArea'];
                let layoutStyle = widgets['layoutStyle'];
                try {
                    let initialContent = document.createElement('template');
                    initialContent.innerHTML = targetEditor.value.trim();
                    let layoutStyleElem = initialContent.content.querySelector('style.layout-style');
                    if (layoutStyleElem !== null) {
                        layoutStyle.innerHTML = layoutStyleElem.innerHTML;
                    }
                    let layoutContentElem = initialContent.content.querySelector('.layout-container');
                    if (layoutContentElem !== null) {
                        var layoutBlocks = layoutContentElem.querySelectorAll('.layout-block');
                        for (let i = 0; i < layoutBlocks.length; i++) {
                            layoutBlocks[i].prepend(templates['blockSelectBtn'].content.firstChild.cloneNode(true));
                        }
                        layoutContentElem.prepend(templates['blockSelectBtn'].content.firstChild.cloneNode(true));
                        //contentArea.outerHTML = layoutContentElem.outerHTML;
                        contentArea = layoutContentElem.cloneNode(true);
                        widgets['contentArea'] = contentArea;
                        //contentArea = bodyContents[0].content.firstChild().cloneNode();
                        //widgets['contentArea'] = widgets['content'].querySelector('.layout-container');
                        if (widgets['contentArea'].getAttribute('data-type') === null) {
                            widgets['contentArea'].setAttribute('data-type', 'layout-container');
                        }
                    }
                }
                catch
                {
                    console.log('Could not parse existing content.');
                }
            }
            else {
                let widgets = editor.widgets;
                let templates = editor.widgetTemplates;
                let contentArea = widgets['contentArea'];
                console.log('empty target');
                contentArea.prepend(templates['blockSelectBtn'].content.firstChild.cloneNode(true));
            }
            this.updatePreview();
        }
    });

    global.initLayoutEditor = function (target, options) {
        if (target.length === 0) return;
        var defOptions = {};
        if (global.uiCulture != null && global.uiCulture !== '') {
            defOptions['lang'] = global.uiCulture;
        }
        $.extend(defOptions, options);
        defOptions['target'] = target.attr('id');
        defOptions['id'] = 'layout-editor-' + target.attr('id');
        defOptions['styles'] = target.attr('data-css');
        defOptions['usable-style-links'] = target.attr('data-usable-css');
        let layoutStorageProvider = target.data('layout-storage');
        if (layoutStorageProvider != null && typeof (global.layoutStorageProviders[layoutStorageProvider]) === 'function') {
            defOptions['layoutStorageProvider'] = layoutStorageProvider;
        }
        let layoutStorageProviderOptions = target.data('layout-storage-options');
        if (layoutStorageProviderOptions != null) {
            defOptions['layoutStorageProviderOptions'] = layoutStorageProviderOptions;
        }
        let fragmentStorageProvider = target.data('fragment-storage');
        if (fragmentStorageProvider != null && typeof (global.fragmentStorageProviders[fragmentStorageProvider]) === 'function') {
            defOptions['fragmentStorageProvider'] = fragmentStorageProvider;
        }
        let fragmentStorageProviderOptions = target.data('fragment-storage-options');
        if (fragmentStorageProviderOptions != null) {
            defOptions['fragmentStorageProviderOptions'] = fragmentStorageProviderOptions;
        }
        target.layoutEditor(defOptions);
        console.log($('.layout-editor')[0]);
    };

    global.initLayoutEditor($('.layout-editor'), {});

})(jQuery, window);