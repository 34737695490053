﻿(function ($) {
    "use strict";

    $.widget("lms.fractionCalculator", {
        options: {

        },
        _create: function() {
            var options = this.options;
            if (typeof (options.id) === "undefined") {
                options.id = 'fraction-calculator-' + Math.floor(Math.random() * 10000);
            }
            this.element.html('');
            this.element.attr('id', options.id);
            this.element.append(this._generateWidgetElement());
            $('.fraction-value:not([readonly])', $(this.element)).change(function () { this._calculate() }.bind(this));
            $('select#' + options.id + '-operand').change(function () { this._calculate() }.bind(this));
            $(this.element).addClass('widget-ready');
        },
        _generateWidgetElement: function () {
            var options = this.options;
            var calculator = $('<div class="fraction-calculator-body"></div>');
            
            var operand = $('<select class="fraction-operand" tabindex="-1"><option value="+">&plus;</option><option value="-">&minus;</option>' +
                '<option value="*">&times;</option><option value="/">&divide;</option></select>');
            operand.attr('id', options.id + '-operand');
            var operandContainer = $('<div class="fraction-operand-container"></div>');
            operandContainer.append(operand);
            var equals = $('<div class="fraction-equals-container">&equals;</div>');

            calculator.append([this._generateFractionElement(options.id, 'fract1', 1), operandContainer,
                this._generateFractionElement(options.id, 'fract2', 3), equals,
                this._generateFractionElement(options.id, 'fractRes', -1,  true)]);
            return calculator;
        },
        _generateFractionElement: function(baseId, elementKey, tabindex, readonly) {
            var numerator = $('<input class="fraction-value fraction-numerator" type="text"/>');
            numerator.attr('id', baseId + '-' + elementKey + '-num');
            
            var denominator = $('<input class="fraction-value fraction-denominator" type="text"/>');
            denominator.attr('id', baseId + '-' + elementKey + '-denom');
            
            if (readonly === true) {
                denominator.prop('readonly', true);
                numerator.prop('readonly', true);
                numerator.attr('tabindex', -1);
                denominator.attr('tabindex', -1);
            }
            else {
                if (typeof (tabindex) !== undefined && !isNaN(tabindex)) {
                    numerator.attr('tabindex', tabindex++);
                    denominator.attr('tabindex', tabindex);
                }
            }
            var fract = $('<div class="fraction"></div>');
            fract.attr('id', baseId + '-' + elementKey);
            fract.append([numerator, $('<hr/>'), denominator]);
            return fract;
        },
        _calculate: function () {
            if ($('.fraction-value:not([readonly])', $('#' + this.options.id)).filter(function () { return this.value.trim() === ''; }).length === 0) {
                var values = this._getValues();
                if (!isNaN(values.val1.numerator) && !isNaN(values.val1.denominator) 
                        && !isNaN(values.val2.numerator) && !isNaN(values.val2.denominator)) {
                    if (values.val1.denominator !== 0 && values.val2.denominator !== 0) {
                        var op = $('#' + this.options.id + '-operand').val();
                        var resNum = NaN, resDen = NaN;
                        switch (op) {
                            case '+':
                                resNum = values.val1.numerator * values.val2.denominator + values.val2.numerator * values.val1.denominator;
                                resDen = values.val1.denominator * values.val2.denominator;
                                break;
                            case '-':
                                resNum = values.val1.numerator * values.val2.denominator - values.val2.numerator * values.val1.denominator;
                                resDen = values.val1.denominator * values.val2.denominator;
                                break;
                            case '*':
                                resNum = values.val1.numerator * values.val2.numerator;
                                resDen = values.val1.denominator * values.val2.denominator;
                                break;
                            case '/':
                                resNum = values.val1.numerator * values.val2.denominator;
                                resDen = values.val1.denominator * values.val2.numerator;
                                break;
                        }
                        if (!isNaN(resNum) && !isNaN(resDen)) {
                            this._setResult(resNum, resDen);
                        }
                    }
                }
            }
        },
        _setResult: function(numerator, denominator) {
            $('#' + this.options.id + '-fractRes-denom').val(denominator);
            $('#' + this.options.id + '-fractRes-num').val(numerator);
        },
        _getValues: function () {
            return {
                "val1": this._getFractionValues('fract1'),
                "val2": this._getFractionValues('fract2')
            };
        },
        _getFractionValues: function (elemKey) {
            return {
                "numerator": parseInt($('#' + this.options.id + '-' + elemKey + '-num').val()),
                "denominator": parseInt($('#' + this.options.id + '-' + elemKey + '-denom').val())
            };
        },
        _gcd: function (val1, val2) {

        },
        _lcm: function (val1, val2) {

        }

    });

    $('.lms-fraction-calculator.auto-bind:not(.widget-ready)').fractionCalculator();
    $(document).on('click', '.lms-fraction-calculator.click-bind', function () {
        $(this).fractionCalculator();
    });/*
    $(document).on('DOMSubtreeModified', '.lms-fraction-calculator.auto-bind', function () {
        console.log($(this));
    });*/

})(jQuery);