﻿(function ($) {
    "use strict";

    $.widget("lms.ajaxPager", {
        options: {

        },
        _create: function () {
            var options = this.options;
            options.targetContainer = this.element.data("target-container");
            if (typeof (options.targetContainer) === "undefined" || options.targetContainer.length == 0) {
                return;
            }
            if ($(options.targetContainer).length == 0) {
                return;
            }
            $('.btn', $(this.element)).on('click', function (e) {
                let $elem = $(e.target);

                if ($elem.hasClass('disabled')) {
                    return false;
                }
                var options = this.options;
                let $pagerElem = $(this.element);

                var $replacementTarget = $(options.targetContainer);


                let $table = $replacementTarget.parent();
                $table.addClass('loading');
                var url = $elem.attr('href');

                $.ajax({
                    type: 'GET',
                    url: url,
                    success: function (data) {
                        let $doc = $(data);
                        let pageTitle = $('title', $doc).text();
                        if (typeof (history.pushState) === "function") {
                            history.pushState({ urlPath: url }, pageTitle, url);
                        }
                        var $newContent = $($(options.targetContainer, $doc)[0].outerHTML);

                        $replacementTarget.fadeOut(function () {
                            $replacementTarget.replaceWith($newContent);
                            $newContent.hide().fadeIn('fast');
                            $table.removeClass('loading');
                        });
                        let activePage = $('.page-link.active', $doc).data('page');

                        let nextEnabled = $('.btn-next.disabled', $doc).length === 0;
                        let prevEnabled = $('.btn-prev.disabled', $doc).length === 0;
                        if (nextEnabled === true) {
                            $('.btn-next', $pagerElem).removeClass('disabled');
                        }
                        else {
                            $('.btn-next', $pagerElem).addClass('disabled');
                        }
                        if (prevEnabled === true) {
                            $('.btn-prev', $pagerElem).removeClass('disabled');
                        }
                        else {
                            $('.btn-prev', $pagerElem).addClass('disabled');
                        }
                        $('.btn-prev').attr('href', $('.btn-prev', $doc).attr('href'));
                        $('.btn-next').attr('href', $('.btn-next', $doc).attr('href'));
                        $('.page-link', $pagerElem).removeClass('active');
                        $('.page-link[data-page="' + activePage + '"]').addClass('active');
                    }.bind(this)
                });

                return false;
            }.bind(this));
        },
    });
    $('.ajax-pager').ajaxPager();
})(jQuery);