﻿(function ($, global) {
    "use strict";
    $(document).ready(function () {
        // disable validation on hidden elements
        $('form').each(function () {
            if ($(this).data('validator'))
                $(this).data('validator').settings.ignore = ".note-editor *";
        });
        if(typeof(jQuery.validator) !== 'undefined')
        {
            jQuery.validator.setDefaults({
                // This will ignore all hidden elements alongside `contenteditable` elements
                // that have no `name` attribute
                ignore: ":hidden, [contenteditable='true']:not([name])"
            });
        }
        // toolbar buttons
        var LmsFractionsCalculatorButton = function (context) {
            var ui = $.summernote.ui;
            var button = ui.button({
                className: 'lms-fractions-calculator-toolbar-btn',
                contents: 'x/y',
                container: 'body',
                tooltip: 'Insert fractions calculator',
                click: function () {
                    context.invoke('editor.pasteHTML', '<div class="lms-fraction-calculator lms-widget auto-bind">[[ Fractions Calculator Placeholder ]]</div><br/>');
                }
            });
            return button.render();   // return button as jquery object
        };

        var LmsFractionsTrainerButton = function (context) {
            var ui = $.summernote.ui;
            var button = ui.button({
                className: 'lms-fractions-trainer-toolbar-btn',
                contents: 'x/y?',
                container: 'body',
                tooltip: 'Insert fractions trainer',
                click: function () {
                    context.invoke('editor.pasteHTML', '<div class="lms-fraction-trainer lms-widget auto-bind">[[ Fractions Trainer Placeholder ]]</div><br/>');
                }
            });
            return button.render();   // return button as jquery object
        };
        var LmsPwdStrTrainer = function (context) {
            var ui = $.summernote.ui;
            var button = ui.button({
                className: 'lms-pwd-str-trainer-toolbar-btn',
                contents: 'p-str',
                container: 'body',
                tooltip: 'Insert password strength trainer',
                click: function () {
                    context.invoke('editor.pasteHTML', '<div class="lms-pwd-str-trainer lms-widget auto-bind">[[ Password Strength Trainer Placeholder ]]</div><br/>');
                }
            });
            return button.render();
        };
        var LmsModalImage = function (context) {
            var ui = $.summernote.ui;
            var button = ui.button({
                className: 'lms-editor-toolbar-toggle-modal-img',
                contents: 'modal image',
                tooltip: 'Mark image as modal',
                click: function () {
                    let img = $(context.layoutInfo.editable.data('target'));
                    $('.note-image-popover:visible').attr('style', '');
                    $('.note-control-selection:visible').attr('style', '');
                    img.toggleClass('modal-image');
                    let code = context.invoke('code');
                    context.$note.val(code);
                }
            });
            return button.render();
        };
        var LmsImageSidebarLeft = function (context) {
            var ui = $.summernote.ui;
            var button = ui.button({
                className: 'lms-editor-toolbar-toggle-snap-left',
                contents: 'snap left',
                tooltip: 'Snap image left',
                click: function () {
                    let img = $(context.layoutInfo.editable.data('target'));
                    let parent = img.parent('p');
                    $('.note-image-popover:visible').attr('style', '');
                    $('.note-control-selection:visible').attr('style', '');
                    img.removeClass('snap-right');
                    img.addClass('snap-left');
                    if (parent !== null) {
                        img.insertBefore(parent);
                    }
                    let code = context.invoke('code');
                    context.$note.val(code);
                }
            });
            return button.render();
        };
        var LmsImageSidebarRight = function (context) {
            var ui = $.summernote.ui;
            var button = ui.button({
                className: 'lms-editor-toolbar-toggle-snap-right',
                contents: 'snap right',
                tooltip: 'Snap image right',
                click: function () {
                    let img = $(context.layoutInfo.editable.data('target'));
                    $('.note-image-popover:visible').attr('style', '');
                    $('.note-control-selection:visible').attr('style', '');
                    img.removeClass('snap-left');
                    img.addClass('snap-right');
                    let parent = img.parent('p');
                    if (parent !== null) {
                        img.insertBefore(parent);
                    }
                    let code = context.invoke('code');
                    context.$note.val(code);
                }
            });
            return button.render();
        };

        var LmsStripTextStylesButton = function(context) {
            var ui = $.summernote.ui;
            var button = ui.button({
                className: 'lms-editor-toolbar-strip-text-styles',
                contents: 'strip text styles',
                tooltip: 'Strip text styles',
                click: function (ev) {
                    let code = context.invoke('code');
                    let doc = $('<div/>').append(code);
                    $('p,span,b,i,em,ul,li,h1,h2,h3,h4,h5,h6', doc).removeAttr('style');
                    let newCode = doc.html();
                    context.invoke('code', newCode);
                    ev.preventDefault();
                }
            });
            return button.render();
        };
        var LmsCustomStylesButton = function (context) {
            var ui = $.summernote.ui;
            var btnGrp = ui.buttonGroup([
                ui.button({
                    className: 'dropdown-toggle',
                    contents: '<span class="fas fa-pen-fancy"></span> <span class="caret"></span>',
                    tooltip: 'Custom Styles',
                    data: {
                        toggle: 'dropdown'
                    }
                }),
                ui.dropdown({
                    className: 'drop-default summernote-list',
                    contents: '<div class="summernote-custom-style-list">' +
                        '<button class="summernote-custom-style-item" data-wrapelem="span" data-wrapclass="listitem-label"><span class="listitem-label">' +
                            global._lmsToolkit.getLocalizedString('List Item Label') + '</span></button>' +
                        '<button class="summernote-custom-style-item" data-wrapelem="h2" data-wrapclass="section-title"><span class="section-title">' +
                            global._lmsToolkit.getLocalizedString('Section Title') + '</span></button>' +
                        '<button class="summernote-custom-style-item" data-wrapelem="h3" data-wrapclass="subsection-title"><span class="subsection-title">' +
                            global._lmsToolkit.getLocalizedString('Subsection Title') + '</span></button>' +
                        '</div>',
                    callback: function ($dropdown) {
                        $dropdown.find(".summernote-custom-style-list .summernote-custom-style-item").click(function (ev) {
                            if ($(this).data('wrapelem') !== '') {
                                let tag = $(this).data('wrapelem');
                                let tagClass = $(this).data('wrapclass');
                                let rng = context.invoke('createRange');
                                rng.pasteHTML('<' + tag + ' class="' + tagClass + '">' + rng.toString() + '</' + tag + '>');
                                let code = context.invoke('code');
                                context.invoke('code', code);
                                //context.$note.val(code);
                            }
                            if (($(this).data('targetelem') || '') !== '' && ($(this).data('targetclass') || '') !== '') {
                                let rng = context.invoke('createRange');
                                let rngContent = rng.toString();
                                if (rngContent !== '' && rngContent !== null) {
                                    let tElem = $(rng.toString());
                                    $($(this).data('targetelem'), tElem).addClass($(this).data('targetclass'));
                                    rng.pasteHTML(rng.toString());
                                    let code = context.invoke('code');
                                    context.invoke('code', code);
                                    //context.$note.val(code);
                                }
                            }
                            ev.preventDefault();
                        });
                    }
                })
            ]);

            return btnGrp.render();
        };

        var LmsCustomContainers = function(context) {
            let ui = $.summernote.ui;
            let btnGroup = ui.buttonGroup([
                ui.button({
                    className: 'dropdown-toggle',
                    contents: '<span class="fas fa-grip-horizontal"></span> <span class="caret"></span>',
                    tooltip: 'Custom Containers',
                    data: {
                        toggle: 'dropdown'
                    }
                }),
                ui.dropdown({
                    className: 'drop-default summernote-list',
                    contents: '<div class="summernote-custom-container-list">' +
                        '<button class="summernote-custom-container-item" data-container-type="2-col50"><span>' +
                            global._lmsToolkit.getLocalizedString('50/50 2-col') + '</span></button>' +
                        '<button class="summernote-custom-container-item" data-container-type="2-col6-4"><span>' +
                            global._lmsToolkit.getLocalizedString('60/40 2-col') + '</span></button>' +
                        '<button class="summernote-custom-container-item" data-container-type="2-col4-6"><span>' +
                            global._lmsToolkit.getLocalizedString('40/60 2-col') + '</span></button>' +
                        '<button class="summernote-custom-container-item" data-container-type="flex-horizontal"><span>' +
                            global._lmsToolkit.getLocalizedString('Horizontal Flex Container') + '</span></button>' +
                        '<button class="summernote-custom-container-item" data-container-type="flex-vertical"><span>' +
                            global._lmsToolkit.getLocalizedString('Vertical Flex Container') + '</span></button>' +
                        '<button class="summernote-custom-container-item" data-container-type="flex-item"><span>' +
                            global._lmsToolkit.getLocalizedString('Flex Container Item') + '</span></button>' +
                        '</div>',
                    callback: function($dropdown) {
                        $dropdown.find('.summernote-custom-container-list .summernote-custom-container-item').click(function(ev){
                            let containerType = $(this).data('container-type');
                            let container = $('<div class="custom-container"></div>');
                            if(containerType === '2-col50')
                            {
                                container.addClass('cont-2col50');
                                container.append('<div class="container-column"></div>');
                                container.append('<div class="container-column"></div>');
                            }
                            else if(containerType === '2-col6-4')
                            {
                                container.addClass('cont-2col6-4');
                                container.append('<div class="container-column w60"></div>');
                                container.append('<div class="container-column w40"></div>');
                            }
                            else if(containerType === '2-col4-6')
                            {
                                container.addClass('cont-2col4-6');
                                container.append('<div class="container-column w40"></div>');
                                container.append('<div class="container-column w60"></div>');
                            }
                            else if(containerType === 'flex-horizontal')
                            {
                                container.addClass('flex-container horizontal');
                                container.append('<div class="flex-item">Item 1</div>');
                            }
                            else if(containerType === 'flex-vertical')
                            {
                                container.addClass('flex-container vertical');
                                container.append('<div class="flex-item">Item 1</div>');
                            }
                            else if(containerType === 'flex-item')
                            {

                            }
                            if(container.children().length > 0)
                            {
                                let code = context.invoke('code');
                                let doc = $('<div/>').append(code);
                                doc.append(container);
                                let newCode = doc.html();
                                context.invoke('code', newCode);
                            }
                            ev.preventDefault();
                        });
                    }
                })
            ]);
            return btnGroup.render();
        };
        global.initSummernote = function (target, options) {
            var defOptions = {
                height: 250,
                focus: false,
                onCreateLink: function (url) {
                    return url;
                },
                options: {
                    onCreateLink: function (url) {
                        return url;
                    }
                },
                toolbar: [
                    ['style', ['fontname', 'fontsize', 'color', 'bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear', 'lmsStripTextStyles']],
                    ['Paragraph style', ['style', 'ol', 'ul', 'paragraph', 'height']],
                    ['Insert', ['picture', 'link', 'video', 'table', 'hr']],
                    ['Misc', ['codeview', 'undo', 'redo', 'help']],
                    ['lmsWidgets', ['lmsFractionsCalculator', 'lmsFractionsTrainer', 'lmsPwdStrTrainer']],
                    ['lmsStyle', ['lmsCustomStyles', 'lmsCustomContainers']]
                ],
                popover: {
                    image: [
                        //['custom', ['captionIt']],
                        ['image', ['resizeFull', 'resizeHalf', 'resizeQuarter', 'resizeNone']],
                        ['float', ['floatLeft', 'floatRight', 'floatNone']],
                        ['remove', ['removeMedia']],
                        ['lmsImageStyle', ['modalImage', 'sidebarLeft', 'sidebarRight']]
                    ],
                },
                buttons: {
                    lmsFractionsCalculator: LmsFractionsCalculatorButton,
                    lmsFractionsTrainer: LmsFractionsTrainerButton,
                    lmsPwdStrTrainer: LmsPwdStrTrainer,
                    lmsCustomStyles: LmsCustomStylesButton,
                    modalImage: LmsModalImage,
                    sidebarLeft: LmsImageSidebarLeft,
                    sidebarRight: LmsImageSidebarRight,
                    lmsStripTextStyles: LmsStripTextStylesButton,
                    lmsCustomContainers: LmsCustomContainers,
                },
                styleTags: [
                    'p',
                    { title: 'Blockquote', tag: 'blockquote', className: 'blockquote', value: 'blockquote' },
                    'pre', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
                    { title: 'List ItemLabel', tag: 'span', className: 'listitem-label', value: 'span'},
                    { title: 'Section Title', tag: 'span', className: 'section-title', value: 'span'},
                ]
            };
            if (global.uiCulture != null && global.uiCulture !== '') {
                defOptions['lang'] = global.uiCulture;
            }
            $.extend(defOptions, options);
            target.summernote(defOptions);
        };
    });
})(jQuery, window);