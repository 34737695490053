﻿(function ($) {
    "use strict";

    $.widget("lms.pwdStrTrainer", {
        options: {

        },
        strings: {
            "too_short": {
                "default": "Password is too short. A strong password must be at least 8 characters long.",
                "tr-TR": "Şifre çok kısa. Güçlü bir şifre en az 8 karakter uzunluğunda olmalıdır."
            },
            "length_ok": {
                "default": "Password is longer than 8 characters.",
                "tr-TR": "Şifre 8 karakterden uzun."
            },
            "has_lowercase": {
                "default": "Password has lower case characters.",
                "tr-TR": "Şifre küçük harfler içeriyor."
            },
            "no_lowercase": {
                "default": "Password has no lower case characters.",
                "tr-TR": "Şifre küçük harf içermiyor."
            },
            "has_uppercase": {
                "default": "Password has upper case characters.",
                "tr-TR": "Şifre büyük harfler içeriyor."
            },
            "no_uppercase": {
                "default": "Password has no upper case characters.",
                "tr-TR": "Şifre büyük harf içermiyor."
            },
            "has_number": {
                "default": "Password has digits.",
                "tr-TR": "Şifre rakamlar içeriyor."
            },
            "no_number": {
                "default": "Password has no digits.",
                "tr-TR": "Şifre rakamlar içermiyor."
            },
            "has_symbol": {
                "default": "Password has symbol characters.",
                "tr-TR": "Şifre noktalama işaretleri içeriyor."
            },
            "no_symbol": {
                "default": "Password has no symbol characters.",
                "tr-TR": "Şifre noktalama işaretleri içermiyor."
            },
            "inf_pass_lbl": {
                "default": "A strong password:",
                "tr-TR": "Güçlü bir şifre:"
            },
            "inf_pass_length": {
                "default": "must be at least 8 characters long.",
                "tr-TR": "en az 8 karakter uzunluğunda olmalıdır.",
            },
            "inf_pass_lcase": {
                "default": "must have at least one lower case character.",
                "tr-TR": "en az bir adet küçük harf içermelidir.",
            },
            "inf_pass_ucase": {
                "default": "must have at least one upper case character.",
                "tr-TR": "en az bir adet büyük harf içermelidir.",
            },
            "inf_pass_num": {
                "default": "must have at least one digit.",
                "tr-TR": "en az bir adet rakam içermelidir.",
            },
            "inf_pass_symbol": {
                "default": "must have at least one symbol character.",
                "tr-TR": "en az bir adet noktalama işareti içermelidir.",
            },
            "input_placeholder": {
                "default": "Enter a password",
                "tr-TR": "Denemek için bir şifre girin"
            },
            "lvl_low": {
                "default": "Low",
                "tr-TR": "Düşük",
            },
            "lvl_medium": {
                "default": "Medium",
                "tr-TR": "Orta",
            },
            "lvl_high": {
                "default": "High",
                "tr-TR": "Yüksek",
            },
            "lvl_vhigh": {
                "default": "Very High",
                "tr-TR": "Çok Yüksek",
            }
        },
        _getString: function (key, culture) {
            let strings = this.strings;

            return typeof (strings[key]) === "undefined" ? key :
                (typeof (strings[key][culture]) !== "undefined" ? strings[key][culture] :
                    (typeof (strings[key]["default"]) !== "undefined" ? strings[key]["default"] : key));
        },
        defaultNotes: [],
        _create: function () {
            var options = this.options;
            if (typeof (options.id) === "undefined") {
                options.id = 'pwd-str-trainer-' + Math.floor(Math.random() * 10000);
            }
            if (typeof (options.culture) === "undefined") {
                options.culture = 'tr-TR';
            }
            this.element.html('');
            this.element.attr('id', options.id);
            this.element.append(this._generateWidgetElement());
            this.defaultNotes = [
                ["", this._getString("inf_pass_lbl", options.culture)],
                ["info", this._getString("inf_pass_length", options.culture)],
                ["info", this._getString("inf_pass_lcase", options.culture)],
                ["info", this._getString("inf_pass_ucase", options.culture)],
                ["info", this._getString("inf_pass_num", options.culture)],
                ["info", this._getString("inf_pass_symbol", options.culture)]
            ];
            this._updateNotes(this.defaultNotes);
        },
        _generateWidgetElement: function () {
            let options = this.options;
            let trainer = $('<div class="pwd-str-trainer-body"></div>');
            let inputPanel = $('<div class="trainer-panel"></div>');
            let infoPanel = $('<div class="trainer-info"></div>');
            let inputField = $('<input type="text" class="pwd-input"/>');
            inputField.attr('id', options.id + '-pwd-input');
            inputField.attr('autocomplete', 'off');
            inputField.attr('placeholder', this._getString('input_placeholder', options.culture));
            inputField.keyup(function () {
                if (!isNaN(this.timeoutId)) {
                    clearTimeout(this.timeoutId);
                }
                this.timeoutId = setTimeout(function () {
                    this._calculateStrength();
                }.bind(this), 500);
            }.bind(this));
            inputPanel.append($('<div class="pwd-input-wrapper"></div>').append(inputField));
            inputPanel.append($('<div class="pwd-str-meter-wraper"></div>')
                .append($('<span class="pwd-str-meter"></span>').attr('id', options.id + '-pwd-str-meter')));
            inputPanel.append($('<span class="pwd-str-meter-label"></span>').attr('id', options.id + '-pwd-str-level'));
            trainer.append(inputPanel);
            let infoList = $('<ul class="pwd-str-info-list"></ul>');
            infoList.attr('id', options.id + '-info-list');
            infoPanel.append(infoList);
            trainer.append(infoPanel);
            $(this.element).append(trainer);
            $(this.element).addClass('widget-ready');
        },
        
        _calculateStrength: function () {
            console.log('calcStr');
            let pwd = $('.pwd-input', $(this.element)).val() || '';

            let options = this.options;
            let notes = [];
            let strength = 0;
            if (pwd.length > 3) {
                if (pwd.length < 8) {
                    notes.push(["error", this._getString("too_short", options.culture)]);
                }
                else {
                    notes.push(["good", this._getString("length_ok", options.culture)]);
                }
                let lengthScore = Math.log(pwd.length) * 20;
                strength += lengthScore > 60 ? 60 : (lengthScore < 0 ? 0 : lengthScore);
                let lCaseCount = (pwd.match(/[a-zıçöşğü]/g) || []).length;
                let uCaseCount = (pwd.match(/[A-ZİÇÖŞĞÜ]/g) || []).length;
                let numCount = (pwd.match(/[0-9]/g) || []).length;
                let symbolCount = (pwd.match(/[^a-zıçöşğüA-ZİÇÖŞĞÜ0-9]/g) || []).length;
                if (lCaseCount > 0) {
                    notes.push(["good", this._getString("has_lowercase", options.culture)])
                }
                else {
                    notes.push(["error", this._getString("no_lowercase", options.culture)])
                }
                if (uCaseCount > 0) {
                    notes.push(["good", this._getString("has_uppercase", options.culture)])
                }
                else {
                    notes.push(["error", this._getString("no_uppercase", options.culture)])
                }
                if (numCount > 0) {
                    notes.push(["good", this._getString("has_number", options.culture)])
                }
                else {
                    notes.push(["error", this._getString("no_number", options.culture)])
                }
                if (symbolCount > 0) {
                    notes.push(["good", this._getString("has_symbol", options.culture)])
                }
                else {
                    notes.push(["error", this._getString("no_symbol", options.culture)])
                }
                let lCaseScore = (lCaseCount > 0 ? 5 : 0) + Math.log(lCaseCount) * 5;
                strength += lCaseScore > 10 ? 10 : (lCaseScore < 0 ? 0 : lCaseScore);
                let uCaseScore = (uCaseCount > 0 ? 5 : 0) + Math.log(uCaseCount) * 5;
                strength += uCaseScore > 10 ? 10 : (uCaseScore < 0 ? 0 : uCaseScore);
                let numScore = (numCount > 0 ? 5 : 0) + Math.log(numCount) * 5;
                strength += numScore > 10 ? 10 : (numScore < 0 ? 0 : numScore);
                let symScore = (symbolCount > 0 ? 5 : 0) + Math.log(symbolCount) * 5;
                strength += symScore > 10 ? 10 : (symScore < 0 ? 0 : symScore);
                strength = Math.floor(strength * 10) / 10.0;
                this._updateNotes(notes);
            }
            else {
                this._updateNotes(this.defaultNotes);
            }
            this._updatePwdStrength(strength);
        },
        _updatePwdStrength: function (strength) {
            let options = this.options;
            let pwMeter = $('#' + options.id + '-pwd-str-meter');
            
            let lvl = strength <= 0 ? '' : (strength < 50 ? 'low' : (strength < 75 ? 'medium' : (strength < 90 ? 'high' : 'vhigh')));
            pwMeter.animate({
                'width': (strength > 100 ? 100 : (strength < 0 ? 0 : strength)) + '%'
            }, 1000);
            let pwLbl = $('#' + options.id + '-pwd-str-level');
            pwLbl.attr('class', 'pwd-str-meter-label');
            pwMeter.attr('class', 'pwd-str-meter');
            if (lvl !== '') {
                pwLbl.addClass(lvl);
                pwLbl.text(this._getString('lvl_' + lvl, options.culture));
                pwMeter.addClass(lvl);
            }
            else {
                pwLbl.text('');
            }
        },
        _updateNotes: function (notes) {
            let options = this.options;
            let listElem = $('#' + options.id + '-info-list');
            listElem.empty();
            if (typeof (notes) === "undefined"
                || !Array.isArray(notes)) {
                return;
            }
            for (const item of notes) {
                if (typeof (item) !== "undefined"
                    && Array.isArray(item) && item.length >= 2) {
                    listElem.append($('<li>' +
                        (item[0] !== '' ? '<span class= "note-icon ' + item[0] + '" ></span>' : '') +
                        '<span class="note-content">' + item[1] + '</span></li> '));
                }
            }
        }
    });
    $('.lms-pwd-str-trainer.auto-bind:not(.widget-ready)').pwdStrTrainer();
    $(document).on('click', '.lms-pwd-str-trainer.click-bind', function () {
        $(this).pwdStrTrainer();
    });
})(jQuery);